import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  FormControl,
} from '@mui/material';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

const PocAddModel = ({
  open,
  onClose,
  onSave,
  initialData,
  dropdownData,
  programManagers,
  teachingAssistants,
}) => {
  const [poc, setPoc] = useState({
    program: null,
    teachingAssistant: null,
    programManager: null,
  });

  // Notification states
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (initialData) {
      setPoc(initialData);
    } else {
      setPoc({ program: null, teachingAssistant: null, programManager: null });
    }
  }, [initialData]);

  const handleChange = (key, value) => {
    setPoc((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = () => {
    if (!poc.program || !poc.teachingAssistant || !poc.programManager) {
      setHasError(true);
      setErrorMessage('Please fill out all fields before saving.');
      return;
    }

    onSave({
      id: initialData?.id,
      program_id: poc.program?.id,
      teaching_assistant_id: poc.teachingAssistant?.id,
      program_manager_id: poc.programManager?.id,
    });

    setSuccessMessage(
      initialData
        ? 'POC mapping updated successfully!'
        : 'POC mapping added successfully!'
    );
    setIsSuccess(true);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>{initialData ? 'Edit POC' : 'Add New POC'}</DialogTitle>
        <DialogContent>
          {/* Program Dropdown */}
          <FormControl
            fullWidth
            margin='normal'
          >
            <Autocomplete
              options={dropdownData.programs}
              getOptionLabel={(option) => option.internal_name || ''}
              value={poc.program || null}
              onChange={(e, newValue) => handleChange('program', newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Program'
                />
              )}
            />
          </FormControl>

          {/* Teaching Assistant Dropdown */}
          <FormControl
            fullWidth
            margin='normal'
          >
            <Autocomplete
              options={teachingAssistants}
              getOptionLabel={(option) => option.name || ''}
              value={poc.teachingAssistant || null}
              onChange={(e, newValue) =>
                handleChange('teachingAssistant', newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Teaching Assistant'
                />
              )}
            />
          </FormControl>

          {/* Program Manager Dropdown */}
          <FormControl
            fullWidth
            margin='normal'
          >
            <Autocomplete
              options={programManagers}
              getOptionLabel={(option) => option.name || ''}
              value={poc.programManager || null}
              onChange={(e, newValue) =>
                handleChange('programManager', newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Program Manager'
                />
              )}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            color='secondary'
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color='primary'
            variant='contained'
          >
            {initialData ? 'Update POC' : 'Add POC'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Notification */}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      {/* Error Notification */}
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
    </>
  );
};

export default PocAddModel;
