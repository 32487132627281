import * as postRobot from 'post-robot';

export const sendMessage = async (targetWindow, messageType, messageData) => {
  return postRobot
    .send(targetWindow, messageType, messageData)
    .then((response) =>
      console.log('Message sent successfully:', response.data)
    )
    .catch((error) => console.error('Error sending message:', error));
};

export const setupListener = (messageType, callback) => {
  const listener = postRobot.on(messageType, (event) => {
    callback(event.data);
    return { status: 'Received Successfully' };
  });

  return listener;
};
