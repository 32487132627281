import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle>{title || 'Are you sure?'}</DialogTitle>
      <DialogContent>
        <Typography>{message || 'This action cannot be undone.'}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color='secondary'
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color='error'
          variant='contained'
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
