// @ts-nocheck
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  LinearProgress,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import { sop_questions } from '.';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

const ProfileQuestions = ({ sopQuestionIndex }) => {
  const { formId, setShowSopQuestions } = useEfPipeline();
  const [answer, setAnswer] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] =
    useState(sopQuestionIndex);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const questionsArray = Object.values(sop_questions);

  const handleAnswerChange = (event) => setAnswer(event.target.value);

  const progress = ((currentQuestionIndex + 1) / questionsArray.length) * 100;

  const submitQuestionAnswer = async () => {
    setLoading(true);

    const body = {
      question: questionsArray[currentQuestionIndex],
      answer: answer.trim(),
    };
    try {
      const res = await axios.patch(
        `${BASE_URL}/api/eligibility-forms/${formId}/update_sop_questions`,
        body,
        { headers: getHeaders() }
      );

      if (res?.data?.status === 'success') {
        setAnswer('');
        if (currentQuestionIndex < questionsArray.length - 1) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        } else {
          setShowSopQuestions(false);
        }
      }
    } catch (err) {
      setHasError(true);
      setErrorMessage('Error submitting SOP answer');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh',
          padding: 5,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            maxWidth: 900,
            width: '100%',
            padding: 4,
            borderRadius: 2,
            textAlign: 'left',
          }}
        >
          <Typography
            variant='h3'
            fontWeight='bold'
            gutterBottom
            sx={{ color: '#1976d2' }}
          >
            Help us understand your profile better
          </Typography>
          <Typography
            variant='body1'
            sx={{ mb: 2, fontSize: '1rem' }}
          >
            Your responses will give us a clearer understanding of your
            background and goals, and improve your chances of success. Please
            help us by answering a few quick questions.
          </Typography>

          <Box sx={{ width: '100%', mb: 2 }}>
            <Typography
              variant='body1'
              sx={{ textAlign: 'left', display: 'block', mb: '1rem' }}
            >
              Question {currentQuestionIndex + 1}/{questionsArray.length}
            </Typography>
            <LinearProgress
              variant='determinate'
              value={progress}
              sx={{
                height: 12,
                borderRadius: 5,
                backgroundColor: '#f0f0f0',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#1976d2',
                },
              }}
            />
          </Box>

          <Typography
            variant='body1'
            sx={{
              mb: 2,
              textAlign: 'left',
              fontWeight: 'bold',
              lineHeight: '1.6',
              fontSize: '1.1rem',
            }}
          >
            {questionsArray[currentQuestionIndex]}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder='Type your answer...'
            variant='outlined'
            value={answer}
            onChange={handleAnswerChange}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#f9f9f9',
              },
            }}
          />

          <Button
            variant='contained'
            color='primary'
            onClick={submitQuestionAnswer}
            disabled={answer.trim().length < 50 || loading}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              padding: '8px 24px',
              width: { xs: '35%', sm: '25%', md: '25%', lg: '20%' },
              mx: 'auto',
              display: 'block',
            }}
          >
            {loading ? (
              <CircularProgress
                size={24}
                color='inherit'
              />
            ) : (
              'Next →'
            )}
          </Button>
        </Paper>
      </Box>
    </>
  );
};

export default ProfileQuestions;
