export const ProgramQuestions = [
  {
    id: 1,
    text: 'This program is designed to help you achieve your career goals.',
    prompt: 'Are you ready to take this step toward your dream job?',
  },
  {
    id: 2,
    text: "With expert mentors and structured guidance, you'll upskill at your own pace.",
    prompt:
      'Are you ready to dedicate focused time each week to advance your career?',
  },
  {
    id: 3,
    text: 'Our program keeps you on track with clear milestones and expert support.',
    prompt:
      'Do you feel confident about staying committed to your learning journey?',
  },
  {
    id: 4,
    text: 'A successful job switch requires patience.',
    prompt:
      'Will you stay consistent and keep pushing forward, even if you face a few setbacks?',
  },
];
