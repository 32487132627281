import React, { useState, useEffect } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import moment from 'moment';
import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Modal,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PDFViewer from './PDFViewer';
import { getAccessToken } from '../../configs/auth';
import { postTermsAndConditions } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

export default function TermsAndConditionsAgreement({
  pdfBytes,
  setPdfBytes,
  setLoading,
  handleSubmit,
  isAnyAnswerEmpty,
}) {
  const [signature, setSignature] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleSignatureChange = (e) => {
    e.preventDefault();
    setSignature(e.target.value);
  };

  const handleSignPDF = async () => {
    setIsChecked(true);
    if (pdfBytes) {
      try {
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const pages = pdfDoc.getPages();
        const lastPage = pages[pages.length - 1];
        const { width, height } = lastPage.getSize();

        const currentDate = moment();
        const day = currentDate.format('D');
        const month = currentDate.format('MMM');
        const year = currentDate.format('YYYY');
        const time = currentDate.format('h:mm A');

        lastPage.drawText(signature, {
          x: width - 180,
          y: 140, // Adjusted Y position
          size: 12,
          color: rgb(0, 0, 0),
        });

        lastPage.drawText(`Signed at ${day} ${month}`, {
          x: width - 180,
          y: 127,
          size: 9,
          color: rgb(0, 0, 0),
        });

        lastPage.drawText(`${year}, ${time}`, {
          x: width - 180,
          y: 114,
          size: 9,
          color: rgb(0, 0, 0),
        });

        lastPage.drawText('-----------------------', {
          x: width - 180,
          y: 101,
          size: 9,
          color: rgb(0, 0, 0),
        });

        lastPage.drawText('Signature', {
          x: width - 165,
          y: 88,
          size: 9,
          color: rgb(0, 0, 0),
        });

        const modifiedPdfBytes = await pdfDoc.save();
        setPdfBytes(modifiedPdfBytes);
        handleSubmit()
          .then(() => sendPdf(modifiedPdfBytes)) // Call sendPdf only if handleSubmit succeeds
          .catch((error) => {
            console.error('Error in form submission:', error);
            setHasError(true);
            setErrorMessage(
              error.response?.data?.message || 'Failed to submit the form.'
            );
          });
      } catch (error) {
        console.error('Error signing PDF:', error);
      }
    }
  };

  const sendPdf = async (modifiedPdfBytes) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      'pdf_data',
      new Blob([modifiedPdfBytes], { type: 'application/pdf' }),
      'terms_and_conditions'
    );
    // await handleSubmit();
    await axios
      .post(postTermsAndConditions(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Token': getAccessToken(),
        },
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Failed to sign the pdf.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openPdfModal = () => {
    setIsModalOpen(true);
    setIsChecked(true);
  };

  const closePdfModal = () => {
    setIsModalOpen(false);
    setIsChecked(true);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
        }}
      >
        <Modal
          open={isModalOpen}
          onClose={closePdfModal}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <PDFViewer pdfBytes={pdfBytes} />
        </Modal>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              color='primary'
            />
          }
          label={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '7px',
              }}
            >
              <Typography>I have read, understood and accepted the</Typography>
              <Box
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={openPdfModal}
              >
                Terms and Conditions
              </Box>
            </Box>
          }
        />
        <TextField
          variant='outlined'
          required
          type='text'
          label='Enter your Name'
          value={signature}
          disabled={!isChecked}
          onChange={handleSignatureChange}
          sx={{ mt: 2 }}
        />
        <Button
          disabled={signature === '' || !isChecked}
          variant='contained'
          color='primary'
          onClick={handleSignPDF}
          sx={{
            px: 9,
            py: 1,
            mt: 2,
          }}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
