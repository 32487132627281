import axios from 'axios';
import { getHeaders, logoutUrlAuthService } from 'src/apis/urls';

export const isUserLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) {
    return false;
  }
  const accessToken = user.access_token;
  if (accessToken) {
    return true;
  }
  return false;
};

export const setUserLoggedIn = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const setUserLoggedOut = async () => {
  try {
    const response = await axios.post(
      logoutUrlAuthService(),
      {},
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    localStorage.removeItem('user');
    if (response) {
      return true;
    } else {
      console.error('Failed to logout');
    }
  } catch (error) {
    console.error('Failed to logout', error);
    return false;
  }
};

export const setUserData = (user) => {
  localStorage.setItem('currentUser', JSON.stringify(user));
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const getUserType = () => {
  const user = getUserData();
  return user ? user.role : '';
};

export const getUserId = () => {
  const user = getUserData();
  return user ? user.id : '';
};

export const getUserName = () => {
  const user = getUserData();
  return user ? user.name : '';
};

export const getUserMail = () => {
  const user = getUserData();
  return user ? user.email_id : '';
};

export const getAccessToken = () => {
  const user = getUserData();
  return user ? user.access_token : '';
};

export const getUserImage = () => {
  const user = getUserData();
  return user ? user.image : '';
};

export const getUserTncStatus = () => {
  const user = getUserData();
  return user ? user.terms_and_conditions_agreed : '';
};

export const getUserOnboardingStatus = () => {
  const user = getUserData();
  if (user && user.hasOwnProperty('onboarding')) {
    return user.onboarding;
  } else {
    return '';
  }
};

export default {
  isUserLoggedIn,
  setUserLoggedIn,
  setUserLoggedOut,
  setUserData,
  getUserOnboardingStatus,
};
