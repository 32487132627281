import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PocAddModel from 'src/components/modal/PocAddModel';
import ConfirmationDialog from 'src/components/modal/ConfirmationDialog';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import SuccessNotifier from 'src/components/ToastNotifications/SuccessNotifier';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';

const PocMapping = () => {
  const [data, setData] = useState([]); // POC mappings data
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editPoc, setEditPoc] = useState(null);
  const [dropdownData, setDropdownData] = useState({ programs: [], users: [] });
  const [teachingAssistants, setTeachingAssistants] = useState([]);
  const [programManagers, setProgramManagers] = useState([]);

  // Notification state
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch all active POC mappings
  const fetchMappings = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/program_poc_mappings`, {
        headers: getHeaders(),
      });
      setData(response?.data?.mappings || []);
    } catch (error) {
      setHasError(true);
      setErrorMessage('Failed to fetch POC mappings.');
      console.error('Failed to fetch POC mappings:', error);
    }
  };

  // Fetch dropdown data
  const fetchDropdownData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/program_poc_mappings/dropdown_data`,
        { headers: getHeaders() }
      );
      const { programs, users } = response?.data || {};

      // Split users by role
      const assistants =
        users?.filter((user) => user.role === 'teaching_assistant') || [];
      const managers =
        users?.filter((user) => user.role === 'program_manager') || [];

      setDropdownData({ programs, users });
      setTeachingAssistants(assistants);
      setProgramManagers(managers);
    } catch (error) {
      setHasError(true);
      setErrorMessage('Failed to fetch dropdown data.');
      console.error('Failed to fetch dropdown data:', error);
    }
  };

  // Load data on component mount
  useEffect(() => {
    fetchMappings();
    fetchDropdownData();
  }, []);

  // Open modal for adding new POC
  const handleOpenModal = () => {
    setEditPoc(null);
    fetchDropdownData();
    setIsModalOpen(true);
  };

  // Open modal for editing a POC
  const handleEdit = (index) => {
    const selectedMapping = data[index];

    // Map existing IDs to the actual dropdown options
    const initialProgram = dropdownData.programs.find(
      (program) => program.id === selectedMapping.program_id
    );
    const initialAssistant = teachingAssistants.find(
      (user) => user.id === selectedMapping.teaching_assistant_id
    );
    const initialManager = programManagers.find(
      (user) => user.id === selectedMapping.program_manager_id
    );

    setEditPoc({
      ...selectedMapping,
      program: initialProgram || null,
      teachingAssistant: initialAssistant || null,
      programManager: initialManager || null,
    });

    fetchDropdownData();
    setIsModalOpen(true);
  };

  // Handle delete confirmation
  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setIsDeleteDialogOpen(true);
  };

  // Handle soft deleting a POC
  const handleDelete = async () => {
    setIsDeleteDialogOpen(false);
    try {
      await axios.patch(
        `${BASE_URL}/api/program_poc_mappings/${deleteId}/destroy_mapping`,
        null,
        {
          headers: getHeaders(),
        }
      );
      setSuccessMessage('POC mapping deleted successfully!');
      setIsSuccess(true);
      fetchMappings(); // Refresh data after soft deletion
    } catch (error) {
      setHasError(true);
      setErrorMessage('Failed to delete POC mapping.');
      console.error('Failed to delete POC mapping:', error);
    }
  };

  // Handle adding or updating a POC
  const handleSavePoc = async (poc) => {
    try {
      if (poc.id) {
        // Update existing POC
        await axios.patch(
          `${BASE_URL}/api/program_poc_mappings/${poc.id}/update_mapping`,
          poc,
          {
            headers: getHeaders(),
          }
        );
        setSuccessMessage('POC mapping updated successfully!');
        setIsSuccess(true);
      } else {
        // Add new POC
        await axios.post(
          `${BASE_URL}/api/program_poc_mappings/create_mapping`,
          poc,
          {
            headers: getHeaders(),
          }
        );
        setSuccessMessage('POC mapping created successfully!');
        setIsSuccess(true);
      }
      setIsModalOpen(false);
      fetchMappings();
    } catch (error) {
      setHasError(true);
      setErrorMessage(
        error?.response?.data?.error || 'Failed to save POC mapping.'
      );
      console.error('Failed to save POC mapping:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Button
        variant='contained'
        color='primary'
        style={{ marginBottom: '20px' }}
        onClick={handleOpenModal}
      >
        Add POC
      </Button>

      {/* POC Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Program Name</TableCell>
              <TableCell>Teaching Assistant</TableCell>
              <TableCell>Program Manager</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.program_name}</TableCell>
                <TableCell>{row.teaching_assistant_name}</TableCell>
                <TableCell>{row.program_manager_name}</TableCell>
                <TableCell>
                  <IconButton
                    color='primary'
                    onClick={() => handleEdit(index)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color='error'
                    onClick={() => handleDeleteClick(row.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Notification Components */}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {/* Modals */}
      <PocAddModel
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSavePoc}
        initialData={editPoc}
        dropdownData={dropdownData}
        teachingAssistants={teachingAssistants}
        programManagers={programManagers}
      />

      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        title='Delete POC Mapping'
        message='Are you sure you want to delete this mapping? This action cannot be undone.'
      />
    </div>
  );
};

export default PocMapping;
