import {
  Typography,
  Box,
  TextField,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import Select from 'react-select';
import React, { useEffect, useRef, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchDropdownURL } from 'src/apis/urls';
import { error } from 'pdf-lib';

const linkedinRegex = /^https:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9-]+$/;

const TextFieldComponent = ({ value, handleChange, error }) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      value={value}
      onChange={handleChange}
      sx={{ mt: '1em', borderColor: error ? 'red' : '' }}
    />
  );
};

const TextAreaComponent = ({ value, handleChange, error }) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      value={value}
      onChange={handleChange}
      multiline
      minRows={1}
      maxRows={6}
      error={error[13]}
      helperText={error[13] ? 'This field is required' : ''}
      InputProps={{
        sx: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: error[13] ? 'red' : 'rgba(0, 0, 0, 0.23)',
            borderWidth: error[13] ? 2 : 1,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: error[13] ? 'darkred' : 'black',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: error[13] ? 'red' : '#1976d2',
            borderWidth: 2,
          },
        },
      }}
    />
  );
};

const RadioComponent = ({ value, question, handleChange, error }) => {
  return (
    <FormControl
      component='fieldset'
      margin='normal'
      error={error[12]}
      sx={{
        mb: 2,
        '& .MuiRadio-root': {
          color: error[12] ? 'red' : undefined,
        },
      }}
    >
      <RadioGroup
        value={value}
        onChange={handleChange}
        name={`question_${question.id}`}
        row
      >
        {question.options.map((val, index) => (
          <FormControlLabel
            key={index}
            value={val}
            control={<Radio />}
            label={val}
            labelPlacement='bottom'
          />
        ))}
      </RadioGroup>
      {error[12] && <FormHelperText>This field is required</FormHelperText>}
    </FormControl>
  );
};

const CheckboxComponent = ({
  question,
  selectedOptions,
  handleOptionChange,
  error,
}) => {
  return (
    <FormControl
      component='fieldset'
      margin='normal'
      sx={{
        mb: 2,
      }}
    >
      <FormGroup>
        {question.options.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                checked={selectedOptions.includes(option)}
                onChange={handleOptionChange}
                value={option}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const WorkExperience = ({ handleChange, setQuestions, error }) => {
  const [yearValue, setYearValue] = useState('');
  const [monthValue, setMonthValue] = useState('');

  // Helper function to update the combined value as a string and send it to the parent
  const updateValue = (newYearValue, newMonthValue) => {
    const combinedValue = `${newYearValue || '0'} ${newMonthValue || '0'}`;

    handleChange({ target: { value: combinedValue } });
  };

  // Handle year selection
  const handleYearChange = (e) => {
    const newYearValue = e.target.value;
    setYearValue(newYearValue);
    updateValue(newYearValue, monthValue);
  };

  // Handle month selection
  const handleMonthChange = (e) => {
    const newMonthValue = e.target.value;
    setMonthValue(newMonthValue);
    updateValue(yearValue, newMonthValue);
  };

  return (
    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
      {/* Dropdown for Years */}
      <DropdownComponent
        value={yearValue}
        question={{
          id: 'years',
          title: 'Years of Experience',
          options: Array.from(
            { length: 51 },
            (_, i) => `${i} ${i === 1 ? 'year' : 'years'}`
          ),
          qid: 7,
        }}
        handleChange={handleYearChange}
        setQuestions={setQuestions}
        error={error}
      />

      {/* Dropdown for Months */}
      <DropdownComponent
        value={monthValue}
        question={{
          id: 'months',
          title: 'Months of Experience',
          options: Array.from(
            { length: 12 },
            (_, i) => `${i} ${i === 1 ? 'month' : 'months'}`
          ),
        }}
        handleChange={handleMonthChange}
        setQuestions={setQuestions}
      />
    </div>
  );
};

const DropdownComponent = ({
  value,
  question,
  handleChange,
  setQuestions,
  error,
}) => {
  const isError = error?.[question.id] ?? error?.[question?.qid] ?? false;
  const [allowOther, setAllowOther] = useState(false);
  const [customOption, setCustomOption] = useState('');
  const [options, setOptions] = useState([]);

  const [filteredOptions, setFilteredOptions] = useState([]);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');
  const [placeholder, setPlaceholder] = useState(
    `Enter your ${question.title}`
  );

  useEffect(() => {
    const formattedOptions = question.options.map((option) => ({
      label: option,
      value:
        typeof option === 'string'
          ? option.toLowerCase().replace(/\s+/g, '_')
          : option,
    }));

    setFilteredOptions(formattedOptions);
    setOptions(formattedOptions);
  }, [question.options]);

  useEffect(() => {
    if (page > 1) {
      loadOptions();
    }
  }, [page]);

  // Function to load options based on the page
  const loadOptions = async () => {
    if (!question.hasOwnProperty('key') || loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.post(`${fetchDropdownURL()}/listData`, {
        listName: [question.key],
        page: page,
        limit: 50,
      });

      const newOptions = response.data.data[question.key].map(
        (item) => item.label
      );
      const newOptionsObj = response.data.data[question.key].map((item) => ({
        label: item.label,
        value: item.key,
      }));
      setOptions((prevOptions) => [...prevOptions, ...newOptionsObj]);
      // Append the new options to the question's options via setQuestions
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.key === question.key
            ? { ...q, options: [...q.options, ...newOptions] }
            : q
        )
      );

      setHasMore(response.data.data[question.key].length === 50);
    } catch (error) {
      console.error('Failed to load dropdown options:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle search query input
  const handleSearchChange = (query) => {
    setSearchQuery(query);

    if (query) {
      const filtered = question.options
        .filter((option) =>
          String(option).toLowerCase().includes(query.toLowerCase())
        )
        .map((option) => ({
          label: String(option),
          value:
            typeof option === 'string'
              ? option.toLowerCase().replace(/\s+/g, '_')
              : option,
        }));

      setFilteredOptions(filtered);
    } else {
      const filtered = question.options.map((option) => ({
        label: String(option),
        value:
          typeof option === 'string'
            ? option.toLowerCase().replace(/\s+/g, '_')
            : option,
      }));

      setFilteredOptions(filtered);
    }
  };

  // Handle scroll event to load more options
  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleDropdownChange = (selectedOption, enteredText) => {
    if (selectedOption) {
      const selectedValue = selectedOption.value;
      if (selectedValue === 'other' || selectedValue == enteredText) {
        setAllowOther(true);
        setCustomOption('');
        handleChange({ target: { value: 'Other' } });
      } else {
        setAllowOther(false);
        setCustomOption('');
        handleChange({ target: { value: selectedValue } });
      }
    }
  };

  const handleCustomOptionChange = (event) => {
    const customValue = event.target.value;
    setCustomOption(customValue);
    handleChange({ target: { value: customValue } });
  };

  return (
    <FormControl
      fullWidth
      margin='normal'
      sx={{
        mb: 2,
      }}
      error={isError}
    >
      <Select
        value={
          allowOther
            ? { label: 'Other', value: 'other' }
            : options.find((opt) => opt.value === value) || null
        }
        menuPlacement='auto'
        onChange={handleDropdownChange}
        options={[
          ...filteredOptions,
          question.hasOwnProperty('key')
            ? { label: 'Other', value: 'other' }
            : {},
        ]}
        filterOption={() => true}
        isSearchable
        onInputChange={handleSearchChange}
        onMenuScrollToBottom={handleScroll}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        onFocus={() => setPlaceholder('')}
        onBlur={() => setPlaceholder(`Enter your ${question.title}`)}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            padding: '6px 12px',
            paddingLeft: '0',
            backgroundColor: '#fff',
            border: isError ? '2px solid red' : '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '14px',
            width: '100%',
            minHeight: '40px',
            '&:hover': {
              borderColor: '#3f51b5',
            },
            '&:focus': {
              borderColor: '#3f51b5',
              boxShadow: '0 0 0 0.2rem rgba(63, 81, 181, 0.25)',
            },
          }),
          input: (base) => ({
            ...base,
            padding: '6px 8px',
            paddingLeft: '0',
          }),
        }}
      />

      {/* Show custom input when "Other" is selected */}
      {allowOther && (
        <TextField
          value={customOption}
          onChange={handleCustomOptionChange}
          fullWidth
          margin='normal'
          label={`Enter your ${question.title}`}
          helperText={isError ? 'This field is required' : ''}
          helperText={isError ? 'This field is required' : ''}
        />
      )}

      {isError && <FormHelperText>This field is required</FormHelperText>}
    </FormControl>
  );
};

export default DropdownComponent;

export const QuestionElement = ({
  question,
  onChange,
  value,
  selectedOptions,
  setSelectedOptions,
  setCtcError,
  setQuestions,
  error,
}) => {
  const handleOptionChange = (event) => {
    const { value } = event.target;
    const updatedSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    setSelectedOptions(updatedSelectedOptions);
    onChange(question.id, updatedSelectedOptions);
  };

  const handleChange = (event) => {
    const answer = event.target.value;

    if (question.id === 6) {
      if (answer.length <= 5) {
        onChange(question.id, answer);
      }

      return;
    }
    onChange(question.id, answer);

    // If question is for CTC and is invalid, set ctcError
    if (question.id === 9) {
      setCtcError(!isValidCtc(answer));
    }
  };

  // Function to convert CTC to LPA (Lakhs per Annum)
  const formatCtcToLPA = (ctc) => {
    if (!ctc || isNaN(ctc)) return '';
    const ctcInLPA = (ctc / 100000).toFixed(1);
    return `${ctcInLPA} LPA`;
  };

  // Check if the CTC is valid (>= 100000)
  const isValidCtc = (ctc) => {
    return ctc >= 100000;
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant='body1'
        sx={{
          whiteSpace: 'pre-line',
        }}
      >
        {question.title} *
      </Typography>
      {question.type === 'text' && question.id != 11 && (
        <TextFieldComponent
          value={value}
          handleChange={handleChange}
          error={error[1]}
        />
      )}
      {question.type === 'textarea' && (
        <TextAreaComponent
          value={value}
          handleChange={handleChange}
          error={error}
        />
      )}
      {question.type === 'radio' && (
        <RadioComponent
          value={value}
          question={question}
          handleChange={handleChange}
          error={error}
        />
      )}
      {question.type === 'checkbox' && (
        <CheckboxComponent
          selectedOptions={selectedOptions}
          question={question}
          handleOptionChange={handleOptionChange}
          error={error}
        />
      )}
      {question.type === 'dropdown' && question.id === 7 && (
        <WorkExperience
          handleChange={handleChange}
          setQuestions={setQuestions}
          error={error}
        />
      )}
      {question.type === 'text' && question.id === 11 && (
        <TextField
          value={value}
          onChange={handleChange}
          error={(value && !linkedinRegex.test(value)) || error[12]}
          helperText={
            error[12]
              ? 'This field is required'
              : value && !linkedinRegex.test(value)
              ? 'Please enter a valid LinkedIn URL (e.g., https://www.linkedin.com/in/username)'
              : ''
          }
          fullWidth
          sx={{ mt: '1em' }}
          InputProps={{
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: error[12] ? 'red' : 'rgba(0, 0, 0, 0.23)',
                borderWidth: error[12] ? 2 : 1,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: error[12] ? 'darkred' : 'black',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: error[12] ? 'red' : '#1976d2',
                borderWidth: 2,
              },
            },
          }}
        />
      )}
      {question.type === 'dropdown' && question.id != 7 && (
        <DropdownComponent
          value={value}
          question={question}
          handleChange={handleChange}
          setQuestions={setQuestions}
          error={error}
        />
      )}
      {question.id === 9 && question.type === 'number' && (
        <TextField
          value={value}
          onChange={handleChange}
          type='number'
          fullWidth
          placeholder={
            question.placeholder || 'Enter your CTC (Example: 300000)'
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>per year</InputAdornment>
            ),
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: error[9] ? 'red' : 'rgba(0, 0, 0, 0.23)',
                borderWidth: error[9] ? 2 : 1,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: error[9] ? 'darkred' : 'black',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: error[9] ? 'red' : '#1976d2',
                borderWidth: 2,
              },
            },
          }}
          inputProps={{
            onInput: (event) => {
              const input = event.currentTarget;
              input.value = input.value.replace(/[^0-9]/g, ''); // Sanitize input to allow only numbers and dots
            },
            pattern: '[0-9]*', // Guide for numeric input
          }}
          sx={{ mt: '1em' }}
          helperText={error[9] ? 'This field is required' : ''}
          error={error[9]}
        />
      )}
      {question.id === 6 && question.type === 'number' && (
        <TextField
          value={value}
          type='text'
          onChange={handleChange}
          inputProps={{
            onInput: (event) => {
              const input = event.currentTarget;
              input.value = input.value.replace(/[^0-9.]/g, '');
            },
            pattern: '[0-9.]*',
          }}
          InputProps={{
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: error[6] ? 'red' : 'rgba(0, 0, 0, 0.23)',
                borderWidth: error[6] ? 2 : 1,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: error[6] ? 'darkred' : 'black',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: error[6] ? 'red' : '#1976d2',
                borderWidth: 2,
              },
            },
          }}
          placeholder={'Enter your CGPA (Eg: 8.65) or % (Eg: 95.5))'}
          step='0.01'
          min='0'
          fullWidth
          sx={{ mt: '1em' }}
          error={error[6]}
          helperText={error[6] ? 'This field is required' : ''}
        />
      )}

      {/* Display the formatted message for CTC in LPA or error message */}
      {question.id === 9 && question.type === 'number' && value && (
        <>
          {isValidCtc(value) ? (
            <Typography
              variant='body2'
              sx={{ mt: 1 }}
            >
              * Your annual CTC is {formatCtcToLPA(value)}
            </Typography>
          ) : (
            <Typography
              variant='body2'
              sx={{ mt: 1, color: 'red' }}
            >
              * Please Enter a Valid CTC
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
