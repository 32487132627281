import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import MultiListComponent from '../MultiListComponent';
import CustomButton from '../../../practice/MindMaps/Atoms/CustomButton';
import { TrailingZeroesConst } from '../../../utils/techniqueSagaConstant';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const { explanation } = TrailingZeroesConst;
const { optimizedExplanation } = TrailingZeroesConst;
const { TimeComplexity } = TrailingZeroesConst;
const { optimizedTimeComplexity } = TrailingZeroesConst;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TrailingZeroes = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>6. Trailing Zeroes</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: 'fit-content',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Iterative approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Optimised Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='12px'
          >
            <Typography>
              Given an integer A, the task is to count and return the number of
              trailing zeroes in its binary representation.
            </Typography>

            <Box
              display='flex'
              flexDirection='column'
              gap={1}
            >
              <Box>
                <Typography
                  sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}
                >
                  Constraints
                </Typography>
                <List>
                  <ListItem sx={{ paddingY: '2px' }}>
                    <ListItemIcon>
                      <FiberManualRecord sx={{ width: '0.5em' }} />
                    </ListItemIcon>
                    <ListItemText primary='0 <= A <= 10^9' />
                  </ListItem>
                </List>
              </Box>

              <Box>
                <Typography
                  sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}
                >
                  Constraint Analysis
                </Typography>
                <ListRenderComponent data={TrailingZeroesConst.example01} />
              </Box>
            </Box>

            <Box>
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Hints To Solve The Problems
              </Typography>

              <ListRenderComponent data={TrailingZeroesConst.example02} />
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='16px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
                Intuition
              </Typography>

              <ListRenderComponent data={TrailingZeroesConst.example03} />
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='16px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Code
              </Typography>

              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '650px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`Function solve(A):
    binaryString = ConvertIntegerToBinaryString(A)
    count = 0

    /* Iterate through the characters from the right until the 
    first '1' is encountered. */
    For i from length(binaryString) - 1 to 0:
        If binaryString[i] is '0':
            Increment count
        Else:
            Break // Exit the loop when the first '1' is encountered

    Return count
End Function
`}
                </pre>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='10px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Code Walkthrough
              </Typography>

              {explanation.map((data, index) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='12px'
                  key={index}
                >
                  <Typography sx={{ fontSize: '18px' }}>
                    {data.title}
                  </Typography>

                  <MultiListComponent data={data.pointer} />
                </Box>
              ))}
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='12px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Time Complexity Analysis
              </Typography>

              <Box
                display='flex'
                flexDirection='column'
                gap='10px'
              >
                <Typography sx={{ fontSize: '18px' }}>
                  The time complexity of the provided solve method can be
                  analysed as follows:
                </Typography>

                {TimeComplexity.map((data, index) => (
                  <MultiListComponent
                    key={index}
                    data={data.pointer}
                  />
                ))}

                <Typography>
                  Therefore, the overall time complexity of the solve method is
                  O(log A), where A is the input integer.
                </Typography>
              </Box>
            </Box>
          </Box>
        </CustomTabPanel>

        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='16px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
                Intuition
              </Typography>

              <ListRenderComponent data={TrailingZeroesConst.example04} />
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='16px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Code
              </Typography>

              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '650px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`Function solve(A):
    count = 0

    // Iterate through the 32 bits of the integer
    For i from 0 to 31:
        // Check if the i-th bit is 1 using bitwise AND
        If ((A >> i) & 1) == 1:
            Break // Exit the loop when the first '1' is encountered
        Increment count

    Return count
End Function`}
                </pre>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='10px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Code Walkthrough
              </Typography>

              {optimizedExplanation.map((data, index) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='12px'
                  key={index}
                >
                  <Typography sx={{ fontSize: '18px' }}>
                    {data.title}
                  </Typography>

                  <MultiListComponent data={data.pointer} />
                </Box>
              ))}
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='12px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Time Complexity Analysis
              </Typography>

              <Box
                display='flex'
                flexDirection='column'
                gap='10px'
              >
                <Typography sx={{ fontSize: '18px' }}>
                  The time complexity of the provided solve method can be
                  analysed as follows:
                </Typography>

                {optimizedTimeComplexity.map((data, index) => (
                  <MultiListComponent
                    key={index}
                    data={data.pointer}
                  />
                ))}

                <Typography>
                  In summary, the time complexity of the solve method is
                  constant O(1). The loop runs for a fixed number of iterations
                  (32 times), making the time complexity independent of the
                  value of the input integer. The method provides an efficient
                  solution for counting trailing zeros in the binary
                  representation of a 32-bit integer.
                </Typography>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap='12px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                Space Complexity Analysis
              </Typography>

              <Box
                display='flex'
                flexDirection='column'
                gap='10px'
              >
                <Typography sx={{ fontSize: '18px' }}>
                  The space complexity of the provided function solve(A) is
                  O(1).
                </Typography>

                <Typography>
                  The function uses a constant amount of space to store the
                  variable count, which is an integer. Additionally, the loop
                  variable i is also considered to have constant space
                  complexity since it represents a single integer.
                </Typography>
              </Box>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TrailingZeroes;
