// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Grid, Modal } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getUserId, getUserType } from '../../configs/auth';
import ResumeLearningCard from './ResumeLearningCard';
import SignatureFrameworkItem from './SignatureFrameworkItem';
import SessionOverviewItem from './SessionOverviewItem';
import UpcomingSessionsDetails from './UpcomingSessionsDetails';
import ProgressCalendar from './ProgressCalendar';
import {
  activityLogs,
  fetchLearnerOverviewURL,
  getCoupons,
  getHeaders,
} from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import LoadingScreen from '../../pages/LoadingScreen';
import CouponPopover from '../TAPCoupons/Molecules/CouponPopover';
import LineChartComponent from './LineChartComponent';
import { useNavigate } from 'react-router-dom';

export default function Overview() {
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [studentOverview, setStudentOverview] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCouponsPopover, setShowCouponsPopover] = useState(false);
  const [showedFirstTime, setShowedFirstTime] = useState(
    localStorage.getItem('showedCouponPopover') === 'true'
  );
  const shouldSendActivityData = false;

  useEffect(() => {
    if (currentProgram?.programId === 2 && shouldSendActivityData) {
      sendActivityData();
    }

    if (getUserType() === 'student') {
      return;
    }

    axios.get(getCoupons(), { headers: getHeaders() }).then((res) => {
      setShowCouponsPopover(res?.data?.length > 0);
    });
  }, []);

  const {
    assignment,
    attendance,
    mindmap,
    session_test,
    tgp,
    dsa_visualizers,
    technique_activity,
  } = studentOverview;

  useEffect(() => {
    axios
      .get(fetchLearnerOverviewURL(getUserId()), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        setStudentOverview(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err?.message);
        setLoading(false);
      });

    return () => {};
  }, []);

  const sessionOverviewItems = [
    {
      id: 1,
      title: 'Attendance',
      data: {
        total: attendance?.total_attendance || 0,
        completed: attendance?.attendance || 0,
      },
    },
    {
      id: 2,
      title: 'Assignments',
      data: {
        total: assignment?.total_assignments || 0,
        completed: assignment?.submitted_assignments || 0,
      },
    },
    {
      id: 3,
      title: 'Session Tests',
      data: {
        total: session_test?.total_session_tests || 0,
        completed: session_test?.session_test || 0,
      },
    },
  ];

  const signatureFrameworkItems = [
    {
      id: 1,
      title: 'Mindmap',
      icon: '/static/overview-svgs/Mindmap_Mini.svg',
      text: 'problems completed',
      data: {
        total: mindmap?.total_mindmap_problems || 0,
        completed: mindmap?.completed_mindmap_problems || 0,
      },
      onClick: () =>
        navigate(
          `/student/${currentProgram?.programSlug}/signature-frameworks/mindmap`
        ),
    },
    {
      id: 2,
      title: 'TGP',
      icon: '/static/overview-svgs/TGP_Mini.svg',
      text: 'levels',
      data: {
        total: tgp?.total_tgp || 'C10',
        completed: tgp?.max_cleared_tgp_level || 'C0',
      },
      onClick: () =>
        navigate(
          `/student/${currentProgram?.programSlug}/signature-frameworks/the-great-pyramid`
        ),
    },
    {
      id: 3,
      title: 'DSA Visualizer',
      icon: '/static/overview-svgs/DSA_Mini.svg',
      text: 'sub-topics completed',
      data: {
        total: dsa_visualizers?.total_dsa_visualizer_topics || 0,
        completed: dsa_visualizers?.completed_dsa_visualizer_topics || 0,
      },
      onClick: () =>
        navigate(
          `/student/${currentProgram?.programSlug}/signature-frameworks/dsa-visualization`
        ),
    },
    {
      id: 4,
      title: 'Technique Saga',
      icon: '/static/overview-svgs/technique_saga.svg',
      text: 'Techniques completed',
      data: {
        total: technique_activity?.total_technique_activity || 0,
        completed: technique_activity?.technique_activity_completed_count || 0,
      },
      onClick: () =>
        navigate(
          `/student/${currentProgram?.programSlug}/signature-frameworks/technique-saga`
        ),
    },
  ];

  const sendActivityData = () => {
    const activityData = JSON.parse(localStorage.getItem('activityData')) || {};
    // send all data to backend
    const activities = [];

    Object.keys(activityData).forEach((date) => {
      activities.push({
        date: moment(date).format('YYYY-MM-DD'),
        activity_time: activityData[date],
      });
    });

    axios.post(
      activityLogs(),
      {
        activities,
      },
      { headers: getHeaders() }
    );
    localStorage.setItem('activityData', JSON.stringify({}));
  };

  return (
    <Grid
      container
      spacing={2}
    >
      <Modal
        open={showCouponsPopover && !showedFirstTime}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        onClose={() => setShowCouponsPopover(false)}
      >
        <CouponPopover />
      </Modal>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <LoadingScreen loading={loading} />
      <Grid
        item
        lg={8}
      >
        <Stack
          spacing={2}
          sx={{
            padding: '18px 0 18px 0px',
          }}
        >
          <ResumeLearningCard />
          {/* Signature Framework */}
          <Box sx={{}}>
            <Typography
              sx={{
                fontWeight: 'bold',
                marginBottom: '8px',
              }}
            >
              Signature Frameworks
            </Typography>
            <Stack
              spacing={{ xs: 2, sm: 2 }}
              direction='row'
              useFlexGap
              flexWrap='wrap'
            >
              {signatureFrameworkItems.map((item, index) => (
                <SignatureFrameworkItem
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                  data={item.data}
                  onClick={item.onClick}
                  index={index}
                  text={item.text}
                />
              ))}
            </Stack>
          </Box>
          {/* Sessions Overview */}
          <Box sx={{}}>
            <Typography
              sx={{
                fontWeight: 'bold',
                marginBottom: '8px',
              }}
            >
              Sessions Overview
            </Typography>
            <Stack
              spacing={{ xs: 2, sm: 2 }}
              direction='row'
              useFlexGap
              flexWrap='wrap'
            >
              {sessionOverviewItems.map((item, index) => (
                <SessionOverviewItem
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                  data={item.data}
                  onClick={item.onClick}
                  index={index}
                />
              ))}
            </Stack>
          </Box>
          {/* Upcoming sessions details */}
          <UpcomingSessionsDetails />
        </Stack>
      </Grid>

      <Grid
        item
        lg={4}
      >
        <Stack
          spacing={2}
          sx={{
            height: '100%',
          }}
        >
          <ProgressCalendar />
          {getUserType() === 'student' &&
            currentProgram?.programId === 2 &&
            shouldSendActivityData && (
              <>
                <Typography
                  style={{
                    marginLeft: '20px',
                    fontWeight: 700,
                    fontSize: '15px',
                  }}
                >
                  Learning Hours
                </Typography>
                <LineChartComponent />
              </>
            )}
        </Stack>
      </Grid>
    </Grid>
  );
}
