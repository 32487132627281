// @ts-nocheck
import { Button, Stack, TextField, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { Modal } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LanguageSelect } from '../../practice/CodeEditor/LanguageSelect';
import { BASE_URL, getHeaders, postCodeRunURL } from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import CodeMirrorEditor from '../../CodeMirrorEditor/CodeMirrorEditor';
import ProgramNotStarted from '../../ProgramNotStarted';
import { getSubmissionStatusForClient } from '../../practice/CodingTest/DetailedTest/CodingTestCodeEditor';
import { CloseFullscreen, OpenInFull } from '@mui/icons-material';
import { capitalize, makeProblemIdentifier } from 'src/components/utils/common';
import {
  buttonTopbarContainerStyle,
  closeFullScreenStyle,
  openInFullStyle,
  resetButtonStyle,
} from './styles';
const MAX_POLLING_ATTEMPTS = 10;

export default function NewCodingAssignmentCodeEditor({
  selectedAssignment,
  selectedProblem,
  assignmentId,
  setSelectedAssignment,
  problemContext,
}) {
  const problemId = selectedProblem?.id;
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [selectedLanguage, setSelectedLanguage] = useState({
    languageId: 54,
    languageName: 'C++ (GCC 9.2.0)',
  }); // default language
  const [codeArea, setCodeArea] = useState('');
  const [stdin, setStdin] = useState('');
  const [stdout, setStdout] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [pollingAttempts, setPollingAttempts] = useState(0);
  const [attemptId, setAttemptId] = useState(-1);
  const [open, setOpen] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [firstFailedTestcase, setFirstFailedTestcase] = useState(null);
  const [codeEditorResetState, setCodeEditorResetState] = useState('');

  const codeProblemIdentifier = makeProblemIdentifier(
    problemContext,
    selectedLanguage.languageId,
    problemId
  );

  useEffect(() => {
    if (Object.keys(selectedProblem).length > 0) {
      setStdin(selectedProblem?.default_testcase?.input ?? '');
    }
    setStdout('');
    return () => {};
  }, []);

  useEffect(() => {
    const { code_skeletons = [] } = selectedProblem ?? {};
    const codeAreaText = localStorage.getItem(codeProblemIdentifier) ?? '';
    if (code_skeletons.length === 0) {
      return;
    }

    let found = false;
    code_skeletons.forEach((skeleton) => {
      const { language = {} } = skeleton;
      const { judge0_language_id = null } = language;

      if (judge0_language_id === selectedLanguage?.languageId) {
        const isOldSolution =
          !skeleton.skeleton_define_class && skeleton.skeleton_solution_class;

        setOpen(
          !skeleton.skeleton_define_class && !skeleton.skeleton_solution_class
        );

        const driver_code = isOldSolution
          ? skeleton.skeleton_solution_class ?? ''
          : `${skeleton.skeleton_define_class ?? ''}\n\n${
              skeleton.skeleton_class_end ?? ''
            }`;

        setCodeEditorResetState(driver_code);
        setCodeArea(codeAreaText.length > 0 ? codeAreaText : driver_code);

        found = true;
      }
    });

    if (!found) {
      handleCodeAreaChange('');
    }
    return () => {};
  }, [selectedLanguage]);

  useEffect(() => {
    let interval;
    if (attemptId !== -1 && pollingAttempts < MAX_POLLING_ATTEMPTS) {
      interval = setInterval(() => {
        pollForAttemptStatus();
      }, 6 * 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [pollingAttempts, attemptId]);

  const handleCodeAreaChange = useCallback(
    (value, viewUpdate = null) => {
      setCodeArea(value);
      localStorage.setItem(codeProblemIdentifier, value);
    },
    [selectedLanguage]
  );

  if (
    !currentProgram?.hasProgramStarted &&
    selectedAssignment?.category !== 'pre_course'
  ) {
    return <ProgramNotStarted />;
  }

  const stopPolling = async () => {
    await setAttemptId(-1);
    setPollingAttempts(0);
  };

  const getFirstFailedTestcase = (id) => {
    axios
      .get(
        `${BASE_URL}/api/coding_assignment_submissions/${id}/first_failed_testcase`,
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        setFirstFailedTestcase(res.data);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            err.message ??
            "Please check 'Submissions' tab for more details."
        );
      });
  };

  const pollForAttemptStatus = () => {
    axios
      .get(
        `${BASE_URL}/api/coding_assignment_submissions/${attemptId}/submission_status`,
        {
          headers: getHeaders(currentProgram?.programId),
        }
      )
      .then((res) => {
        const isProcessing = res.data.includes('processing');
        const inQueue = res.data.includes('in_queue');
        const results = [];

        if (!isProcessing && !inQueue) {
          results.push('Processed.');
          setFirstFailedTestcase(null);
          getFirstFailedTestcase(attemptId);
          stopPolling();
        } else {
          results.push('Processing...');
          setPollingAttempts((prev) => prev + 1);
        }

        results.push(
          ...res.data.map(
            (d, i) => `Test case #${i + 1}: ${d.split('_').join(' ')}`
          )
        );

        setStdout(results.join('\n'));
      })
      .catch((err) => {
        const message =
          "It took longer than usual to fetch the submission results. Please check the 'submissions' tab for results";
        console.log(err);
        setStdout(err.response?.data?.message || message);
        stopPolling();
      });
  };

  const handleCodeSubmit = async (type) => {
    if (selectedLanguage.languageId === 0) {
      setHasError(true);
      setErrorMessage('Please select a language.');
      return;
    }

    const body = {
      coding_assignment_id: assignmentId,
      language_id: selectedLanguage.languageId,
      source_code: codeArea,
      stdin: stdin.length === 0 ? null : stdin,
    };

    body.problem_id = problemId;

    const url =
      type === 'submit'
        ? `${BASE_URL}/api/coding_assignment_submissions`
        : postCodeRunURL();

    await setStdout('Submitting...');

    axios
      .post(url, body, {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        if (type === 'run') {
          const status = getSubmissionStatusForClient(res.data);
          console.log(status);
          setStdout(status.message);

          if (status?.verdict) {
            setIsSuccess(true);
            setSuccessMessage(status?.description ?? 'Submitted');
          } else {
            setHasError(true);
            setErrorMessage(status?.description ?? 'Submitted');
          }
          return;
        }

        const { submission_id = null } = res.data;
        if (submission_id) {
          (async () => {
            await setStdout('Processing...');
          })();

          setAttemptId(submission_id);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
        setStdout('Submitted');
      });
  };

  const handleTestcaseChange = (event) => {
    setStdin(event.target.value);
  };

  const toggleFullScreen = () => {
    setFullScreen((prev) => !prev);
  };

  return (
    <Box
      style={{
        border: '1px solid #e5e8eb',
        padding: '5px',
      }}
    >
      <Box>
        {hasError && (
          <ErrorNotifier
            message={errorMessage}
            setHasError={setHasError}
          />
        )}
        {isSuccess && (
          <SuccessNotifier
            message={successMessage}
            setIsSuccess={setIsSuccess}
          />
        )}

        <Stack spacing={1}>
          <Box
            sx={
              isFullScreen
                ? {
                    position: 'fixed',
                    top: '80px',
                    left: 0,
                    height: '100vh',
                    width: '100%',
                    zIndex: 9,
                    backgroundColor: '#FFF',
                    marginTop: '10px',
                    transition: 'all 0.3s',
                  }
                : { transition: 'all 0.3s', backgroundColor: '#FFF' }
            }
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <LanguageSelect
                setSelectedLanguage={setSelectedLanguage}
                selectedLanguage={selectedLanguage}
              />
              <Box sx={buttonTopbarContainerStyle}>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleCodeAreaChange(codeEditorResetState);
                  }}
                  sx={resetButtonStyle}
                >
                  Reset Code
                </Button>
                {isFullScreen ? (
                  <CloseFullscreen
                    sx={closeFullScreenStyle}
                    onClick={toggleFullScreen}
                  />
                ) : (
                  <OpenInFull
                    sx={openInFullStyle}
                    onClick={toggleFullScreen}
                  />
                )}
              </Box>
            </Box>

            <CodeMirrorEditor
              content={codeArea}
              handleContentChange={handleCodeAreaChange}
              languageType={selectedLanguage.languageName}
            />
          </Box>

          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{
              mb: 1,
            }}
            alignItems='center'
          >
            <Button
              variant='contained'
              color='primary'
              sx={{
                width: '8rem',
                height: 40,
                mr: 1,
              }}
              onClick={(e) => handleCodeSubmit('run')}
            >
              Run
            </Button>
            <Button
              variant='contained'
              color='success'
              sx={{
                width: '8rem',
                height: 40,
              }}
              onClick={(e) => handleCodeSubmit('submit')}
            >
              Submit
            </Button>
          </Box>

          <Stack
            direction='row'
            spacing={2}
          >
            <Stack style={{ width: '50%' }}>
              <Typography
                variant='body2'
                pl='1em'
              >
                Stdin
              </Typography>
              <TextField
                minRows='10'
                maxRows='10'
                multiline
                defaultValue={stdin}
                style={{
                  padding: 7,
                }}
                onChange={handleTestcaseChange}
              />
            </Stack>

            <Stack style={{ width: '50%' }}>
              <Typography
                variant='body2'
                pl='1em'
              >
                Stdout
              </Typography>
              <TextField
                minRows='10'
                maxRows='10'
                multiline
                defaultValue={stdout}
                style={{
                  padding: 7,
                }}
                inputProps={{ readOnly: true }}
              />
            </Stack>
          </Stack>

          {firstFailedTestcase !== null && (
            <Stack style={{ width: '100%' }}>
              <Typography
                variant='h6'
                pl='1em'
              >
                Testcase:{' '}
                <span style={{ color: 'red' }}>
                  {capitalize(
                    firstFailedTestcase?.execution_service_status
                      ?.split('_')
                      .join(' ')
                  )}
                </span>
              </Typography>
              <TextField
                minRows='10'
                maxRows='10'
                color='error'
                multiline
                defaultValue={[
                  ['Input:', firstFailedTestcase?.input].join('\n'),
                  [
                    'Expected output:',
                    firstFailedTestcase?.expected_output,
                  ].join('\n'),
                ].join('\n\n')}
                style={{
                  padding: 7,
                }}
              />
            </Stack>
          )}
        </Stack>
      </Box>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
          }}
        >
          <Typography
            variant='caption'
            fontSize='16px'
            fontWeight='500'
            color='red'
            marginTop='20px'
            display='block'
          >
            This is to inform you that a this problem does not have a solution
            class or class Defination. we kindly request you to reach out to
            your program manager for further assistance
          </Typography>
          <Typography
            variant='caption'
            fontSize='16px'
            fontWeight='500'
            marginTop='20px'
            display='block'
          >
            Reach-out - support@heycoach.co.in
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}
