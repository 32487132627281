import React from 'react';
import PropTypes from 'prop-types';
import { experimentalStyled as styled } from '@material-ui/core/styles';
// material
import { Box } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserType } from '../configs/auth';
import { theme } from 'twin.macro';

// ----------------------------------------------------------------------

LogoV2.propTypes = {
  sx: PropTypes.object,
};

export default function LogoV2({ sx, shouldRedirectToCurrent }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isProfilePage = location.pathname === '/student/user/settings';
  const moveToAllPrograms = (event) => {
    const user = getUserType();
    if (user !== 'admin') {
      navigate('/student/my-courses');
    }
  };

  const redirectToSelf = () => {
    navigate(location.pathname);
  };
  const MainStyle = styled('div')(({ theme }) => ({
    position: isProfilePage ? 'sticky' : 'fixed',
    top: 0,
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(1),
  }));
  return (
    <MainStyle>
      <Box
        component='img'
        src='/static/heycoach-logo.png'
        sx={{ width: 180, height: 40, ...sx }}
        onClick={shouldRedirectToCurrent ? redirectToSelf : moveToAllPrograms}
        style={{
          cursor: 'pointer',
        }}
      />
    </MainStyle>
  );
}
