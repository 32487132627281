import React from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@material-ui/core';
import StudentScreen from '../screens/StudentScreen';

export default function EscalationMatrix() {
  return (
    <>
      <StudentScreen />
      <Stack
        spacing={2}
        m={2}
      >
        <Typography
          textAlign='center'
          variant='h3'
        >
          Escalation Matrix
        </Typography>
        <Typography component='p'>
          The Program Manager is your initial Point of Contact (POC) for any
          program-related queries. You can reach out to your POC through the
          following channels:
        </Typography>
        <Typography
          component='ul'
          pl='2%'
        >
          <Typography component='li'>
            Chat Option available in your portal.
          </Typography>
          <Typography component='li'>
            Help Section: Raise a ticket by clicking on "Request Callback" from
            your portal.
          </Typography>
          <Typography component='li'>
            Direct Contact: The Program Manager's phone number and email ID,
            which have been shared with you via email.
          </Typography>
        </Typography>
        <Typography component='p'>
          If your concerns or feedback are not adequately addressed by the POC,
          you can escalate them to the PGM Team Leader at{' '}
          <a href='mailto:support@heycoach.co.in'>support@heycoach.co.in</a>.
          This email is actively monitored, and we assure resolution within 24
          to 48 hours.
        </Typography>
        <Typography component='p'>
          If the above channels do not provide a satisfactory resolution, you
          may further escalate the issue to Jai, who oversees Education at
          HeyCoach, at{' '}
          <a href='mailto:jai.kishan@heycoach.co.in'>
            jai.kishan@heycoach.co.in
          </a>
          .
        </Typography>
      </Stack>
    </>
  );
}
