// @ts-nocheck
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Skeleton, Typography } from '@mui/material';
import TermsAndCondtitionsLayout from '../../layouts/termsAndConditions/TermsAndConditionsLayout';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import { QuestionElement } from './QuestionElement';
import { getHeaders, postEligibilityForm } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

export default function FormContainer({ questions, loading, setQuestions }) {
  const {
    setIsSuccess,
    setSuccessMessage,
    formId,
    setShowConversationFlow,
    setShowSopRevisit,
    setSopResponse,
    setShowSopQuestions,
  } = useEfPipeline();

  const [formValues, setFormValues] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [ctcError, setCtcError] = useState(false);
  const [questionErrors, setQuestionErrors] = useState({});

  const handleChange = (questionId, answer) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [questionId]: answer,
    }));
  };

  const handleSubmit = (e) => {
    if (ctcError) {
      setHasError(true);
      setErrorMessage('Please enter a valid CTC.');
      return;
    }

    // Check for empty fields
    const newErrors = {};
    questions.forEach((question) => {
      const value = formValues[question.id];
      if (
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'string' && value.trim() === '')
      ) {
        newErrors[question.id] = true; // Mark question as having an error
      }
    });
    console.log(newErrors);

    setQuestionErrors(newErrors); // Update error state

    if (Object.keys(newErrors).length > 0) {
      setHasError(true);
      setErrorMessage('Please fill all mandatory fields.');
      return;
    }

    // Proceed with form submission
    const body = {
      response: JSON.stringify(formatResponse(formValues)),
    };

    axios
      .post(postEligibilityForm(formId), body, { headers: getHeaders() })
      .then((res) => {
        const {
          sop_answer,
          nudge_for_better_sop,
          sop_questions = false,
          show_conversation_flow = false,
        } = res?.data;
        setSopResponse(sop_answer);
        setShowSopRevisit(nudge_for_better_sop);
        setShowSopQuestions(sop_questions);
        setShowConversationFlow(show_conversation_flow);
        setIsSuccess(true);
        setSuccessMessage(
          res?.data?.message ||
            'Congratulations, your response has been received!'
        );
        setFormValues({});
        setSelectedOptions([]);
        setQuestionErrors({}); // Clear errors after successful submission
      })
      .catch((e) => {
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while submitting the response'
        );
      });
  };

  const formatResponse = (formValues) => {
    return questions.map((question) => ({
      question: question.title,
      answer: formValues[question.id],
    }));
  };

  const isAnyAnswerEmpty = questions.some((question) => {
    const value = formValues[question.id];
    let myans =
      value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'string' && value.trim() === '');

    return (
      value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'string' && value.trim() === '')
    );
  });

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {loading ? (
        <Skeleton
          variant='rectangular'
          sx={{
            width: '90%',
            maxWidth: 800,
            height: 500,
            boxShadow: 1,
            p: 4,
            borderRadius: 5,
            mt: 4,
          }}
        />
      ) : (
        <Box
          sx={{
            width: '90%',
            maxWidth: 800,
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 2,
            p: 4,
            borderRadius: 5,
            overflowY: 'auto',
            mt: 4,
          }}
        >
          <Box
            component='form'
            onSubmit={handleSubmit}
          >
            {questions.map((question) => (
              <QuestionElement
                key={question.id}
                question={question}
                onChange={handleChange}
                value={formValues[question.id] || ''}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setCtcError={setCtcError}
                setQuestions={setQuestions}
                error={questionErrors || false}
              />
            ))}
            <TermsAndCondtitionsLayout
              handleSubmit={handleSubmit}
              isAnyAnswerEmpty={isAnyAnswerEmpty}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
