import React, { useState, useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import routes from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import ErrorBoundary from './utils/ErrorBoundary';
import {
  isUserLoggedIn,
  getUserType,
  setUserLoggedIn,
  getUserOnboardingStatus,
  getUserData,
} from './configs/auth';
import axios from 'axios';
import { sendMessage, setupListener } from './utils/rerouting-handshake';
import { persistor } from './components/redux-store/store';

export default function App() {
  // Local state to hold the token and loading status.
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isThroughRedirection, setIsThroughRedirection] = useState(false);
  const onboarding = getUserOnboardingStatus();
  const [parentOrigin, setParentOrigin] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const isChatOrEscalation =
    window.location.href.includes('/chat') ||
    window.location.href.includes('/escalation-matrix');

  const isGrading = window.location.href.includes('/reports');
  // Set up your routes as usual.
  const routing = useRoutes(routes(isUserLoggedIn(), getUserType()));
  const navigate = useNavigate();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // This function calls the validateUser endpoint
  const validateUser = async (authToken) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_SERVICE_BASE_URL_V2}/validateUser`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log('[Domain B] validateUser Success:', response.data, authToken);

      // 🔹 Check if response.data or response.data.data is missing
      if (!response.data || !response.data.data) {
        console.log(
          '[Domain B] Invalid response structure from server:',
          response
        );

        throw new Error('Invalid response structure from server.');
      }

      if (authToken && response.data.success) {
        const userData = response?.data?.data;
        console.log(userData, response.data.data);

        const access_token = response?.data?.token;
        userData.access_token = access_token;

        if (userData?.is_new_login === true && userData?.role === 'visitor') {
          window.location.replace(`${process.env.REACT_APP_ONBOARDING_DEV}`);
          return;
        } else if (userData?.is_new_login === true) {
          window.location.replace(`${process.env.REACT_APP_HCLP_URL}`);
          return;
        }

        setUserLoggedIn({ ...response.data.data, access_token: authToken });
      }

      if (getUserType() === 'student' && onboarding === 'ongoing') {
        navigate('/onboarding');
      }
      if (
        getUserType() === 'admin' ||
        getUserType() === 'program_manager' ||
        getUserType() === 'finance' ||
        getUserType() === 'problem_setter' ||
        getUserType() === 'teaching_assistant'
      ) {
        navigate('/mentor');
      } else if (getUserType() === 'visitor') {
        navigate('/offer-letters');
      } else if (getUserType() === 'visitor') {
        navigate('/eligibility-forms');
      } else {
        navigate('/student');
      }

      console.log('Navigated to the page');

      // Optionally, do something with the validated user data
      // e.g., store in Redux or local state
      // dispatch(setUserData(response.data));
    } catch (error) {
      console.error('[Domain B] validateUser Error:', error);
      localStorage.clear();
      persistor.purge();
      // Optionally, handle invalid token (logout user, redirect, etc.)
      if (window.parent !== window) {
        // Not embedded, so redirect to login page
        sendMessage(window.parent, 'message', 'INVALID_TOKEN');
        return;
      }
    }
  };

  useEffect(() => {
    if (
      window.parent === window &&
      token &&
      !isChatOrEscalation &&
      !isGrading
    ) {
      validateUser(token);
    }
  }, [token]);

  useEffect(() => {
    if (window.parent === window) {
      const paramToken = params.get('token') || getUserData()?.access_token;
      console.log('paramToken: ', paramToken);
      if (paramToken) {
        setToken(paramToken);
        setLoading(false);
      } else {
        // No token available; redirect to login.
        setLoading(false);
      }
    }
    // else if (window.parent === window && isSafari) {
    //   const paramToken = params.get('token') || getUserData()?.access_token;
    //   console.log('paramToken: ', paramToken);
    //   if (paramToken) {
    //     setToken(paramToken);
    //     setLoading(false);
    //   } else {
    //     // No token available; redirect to login.
    //     setLoading(false);
    //   }
    // }
    else {
      // Page is embedded, so send handshake message.
    }
  }, []);

  useEffect(() => {
    let listener = null;
    if (window.parent !== window) {
      listener = setupListener('LEARNING', (token) => {
        console.log('token received: ', token);
        if (!token) {
          sendMessage(window.parent, 'HCLP', 'INVALID_TOKEN');
        } else {
          setUserLoggedIn({ access_token: token });
          setToken(token);
          sendMessage(window.parent, 'HCLP', 'TOKEN_STORED');
        }
      });

      console.log('Sending CHILD_READY message');
      sendMessage(window.parent, 'HCLP', 'CHILD_READY');
    }
    return () => listener?.cancel();
  }, []);

  // While waiting for the token, display a loader.
  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        Loading, please wait...
      </div>
    );
  }

  // Once the token is available, render the app as usual.
  return (
    <ThemeConfig>
      <ScrollToTop />
      <ErrorBoundary>{routing}</ErrorBoundary>
    </ThemeConfig>
  );
}
