// @ts-nocheck
// scroll bar
import 'simplebar/src/simplebar.css';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation, useParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import App from './App';
import { store } from './components/redux-store/store';
import consumer from './cable';
import { setUserLoggedOut } from './configs/auth';
import './index.css';
import Signup from './pages/Signup';
import { CircularProgress, Box } from '@mui/material';
import ErrorNotifier from './components/ToastNotifications/ErrorNotifier';

axios.defaults.timeout = 60 * 1000;

export const persistor = persistStore(store);

export const clearReduxPersistState = () => {
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
};

const logoutUser = () => {
  setUserLoggedOut();
  clearReduxPersistState();
  googleLogout();
  window.location.href = '/';
};

export const MainApp = () => {
  const isSignup = useLocation().pathname.includes('sign-up');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.code === 'ECONNABORTED' ||
        error.message.includes('timeout') ||
        error?.response?.status === 'canceled'
      ) {
        // Handle timeout errors
        setHasError(true);
        setErrorMessage(
          'Request timed out. Please check your internet connection and try again.'
        );
      } else if (axios.isCancel(error)) {
        // Handle canceled requests
        setHasError(true);
        setErrorMessage('Request was canceled. Please try again.');
      } else if (error?.response?.status === 401) {
        // Unauthorized request
        setHasError(true);
        setErrorMessage(
          error?.response?.data?.message || 'An unexpected error occurred'
        );
        setTimeout(() => {
          logoutUser();
        }, 1500);
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {!isSignup ? (
        <PersistGate
          loading={
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='100vh'
              width='100vw'
            >
              <CircularProgress />
            </Box>
          }
          persistor={persistor}
        >
          <App />
        </PersistGate>
      ) : (
        <Signup />
      )}
    </>
  );
};

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <GoogleOAuthProvider clientId='60670340974-jmh7mg9gkg68hk3jfogv22583k5270ua.apps.googleusercontent.com'>
          <MainApp />
        </GoogleOAuthProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);
