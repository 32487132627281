import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Box, useMediaQuery, useTheme } from '@mui/material';
import LogoV2 from 'src/components/LogoV2';
import SuccessScreen from 'src/components/eligibility-forms/SuccessScreen';
import SignupForm from '../components/LeadGen/SignupForm';
import Calendly from 'src/components/AtomicComponents/CalendlyComp';
import { Typography } from '@mui/material';
import { calendlyURLs } from 'src/apis/urls';

export const Signup = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const pathname = location.pathname;
  const [successStatus, setSuccessStatus] = useState(false);

  // Extract URL parameters
  const URLParams = new URLSearchParams(location.search);
  const utmSource = URLParams.get('utm_source');
  const utmMedium = URLParams.get('utm_medium');
  const utmCampaign = URLParams.get('utm_campaign');
  const handleFormSuccess = () => {
    setSuccessStatus(true);
  };
  const calendlyLinkForCareerCounselling = calendlyURLs().careerCounselling;

  return (
    <div>
      {/* {utmSource}-{utmMedium}-{utmCampaign}-{fbclid} */}
      <LogoV2
        sx={{
          width: isSmallScreen ? 130 : 180,
          height: isSmallScreen ? 30 : 40,
          marginLeft: isSmallScreen ? 2 : 0,
        }}
        shouldRedirectToCurrent
      />
      <Stack
        sx={{
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'linear-gradient(45deg, #99ccff, #ffffcc, #ffffff)',
        }}
      >
        {successStatus ? (
          <>
            <SuccessScreen successMessage='Thank you for signing up! Our team will get in touch with you.' />
            {/* <Typography
              variant='body1'
              component='p'
              align='center'
              color='#777'
              sx={{
                width: '100%',
              }}
            > */}
            <Calendly
              pathname={pathname}
              url={calendlyLinkForCareerCounselling}
            />

            {/* </Typography> */}
          </>
        ) : (
          <Box
            sx={{
              width: isSmallScreen ? '85%' : '100%',
              maxWidth: '500px',
              padding: '20px',
              backgroundColor: 'white',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              mx: isSmallScreen ? 1 : 0,
              mt: isSmallScreen ? 6 : 0,
            }}
          >
            <SignupForm onSuccess={handleFormSuccess} />
          </Box>
        )}
      </Stack>
    </div>
  );
};

export default Signup;
