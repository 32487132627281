// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  setUserLoggedIn,
  getUserType,
  getUserOnboardingStatus,
} from '../../configs/auth';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { sendMessage, setupListener } from 'src/utils/rerouting-handshake';

export default function AuthSocial() {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const onboarding = getUserOnboardingStatus();
  const [params] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [tokenToTransfer, setTokenToTransfer] = useState('');
  const [redirectToDomain, setRedirectToDomain] = useState('');
  // Keep a ref to the iframe so we can postMessage reliably
  const iframeRef = useRef(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    const listener = setupListener('LEARNING', (data) => {
      if (data === 'CHILD_READY') {
        sendMessage(iframeRef.current?.contentWindow, 'HCLP', tokenToTransfer);
        sendMessage(
          iframeRef.current?.contentWindow,
          'ONBOARDING',
          tokenToTransfer
        );
      } else if (data === 'TOKEN_STORED') {
        setShowModal(false);
        window.location.replace(redirectToDomain);
      } else if (data === 'INVALID_TOKEN') {
        console.error('[AuthSocial] Error storing token in Domain B!');
        setShowModal(false);
      } else {
        setHasError(true);
        setErrorMessage('An unexpected error occurred.');
        setShowModal(false);
      }
    });

    return () => listener.cancel();
  }, [redirectToDomain, tokenToTransfer]);

  const responseGoogleSuccess = (response) => {
    const res = {
      credential: response.credential,
    };

    axios
      .post(`${process.env.REACT_APP_AUTH_SERVICE_BASE_URL}/login`, res)
      .then((res) => {
        const userData = res?.data?.data?.user;
        const access_token = res?.data?.data?.token;
        userData.access_token = access_token;

        // userData.is_new_login = true;

        setUserLoggedIn({ ...userData, access_token });

        if (userData?.is_new_login === true && userData?.role === 'visitor') {
          console.log('New User', access_token);
          window.location.href = `${process.env.REACT_APP_ONBOARDING_DEV}?token=${access_token}`;
          return;
        } else if (userData?.is_new_login === true) {
          console.log('New User', access_token);
          window.location.href = `${process.env.REACT_APP_HCLP_URL}?token=${access_token}`;
          return;
        }

        // if (userData?.is_new_login === true && userData?.role === 'visitor') {
        //   console.log('New User', access_token);
        //   setTokenToTransfer(access_token);
        //   setShowModal(true);
        //   setRedirectToDomain(`${process.env.REACT_APP_ONBOARDING_DEV}`);
        //   return;
        // } else if (userData?.is_new_login === true) {
        //   console.log('New User', access_token);
        //   setTokenToTransfer(access_token);
        //   setShowModal(true);
        //   setRedirectToDomain(`${process.env.REACT_APP_HCLP_URL}`);
        //   return;
        // }

        if (params.get('location') === 'sync') {
          window.location.href = `${process.env.REACT_APP_SYNC_URL}/auth/post-token?token=${access_token}`;
        }

        if (getUserType() === 'student' && onboarding === 'ongoing') {
          navigate('/onboarding');
        }
        if (
          getUserType() === 'admin' ||
          getUserType() === 'program_manager' ||
          getUserType() === 'finance' ||
          getUserType() === 'problem_setter' ||
          getUserType() === 'teaching_assistant'
        ) {
          navigate('/mentor');
        } else if (getUserType() === 'visitor') {
          navigate('/offer-letters');
        } else if (getUserType() === 'visitor') {
          navigate('/eligibility-forms');
        } else {
          navigate('/student');
        }
      })
      .catch((err) => {
        console.log(err.toString());
        setHasError(true);
        setErrorMessage(`An unexpected error occured: ${err.message}`);
      });
  };

  const responseGoogleFailure = (response) => {
    console.log(response);
    setHasError(true);
    setErrorMessage(`Google OAuth Failed: ${response?.error}`);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      <GoogleLogin
        onSuccess={(credentialResponse) => {
          responseGoogleSuccess(credentialResponse);
        }}
        onError={(e) => {
          responseGoogleFailure(e);
        }}
        shape='pill'
        theme='filled_blue'
        useOneTap={!isSafari}
        use_fedcm_for_prompt
      />
      {/* ✅ Modal using Material-UI */}
      <Dialog
        open={showModal && !isSafari}
        maxWidth='sm'
        minWidth='340px'
        PaperProps={{
          sx: {
            maxHeight: '450px', // Set max height
            borderRadius: '12px', // Optional: Rounded corners for a better look
          },
        }}
      >
        <div className='flex items-center justify-center w-full h-full'>
          <Box
            component='img'
            src='/static/heycoach-logo.png'
            sx={{ width: 150, height: 40 }}
            style={{
              cursor: 'pointer',
              display: 'block', // Ensure no extra spacing issues
            }}
          />
        </div>
        <DialogTitle
          sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '16px' }}
        >
          Processing login, please wait...
        </DialogTitle>
        <DialogContent>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            sx={{ height: '100%' }}
          >
            {/* Loader */}
            <CircularProgress sx={{ my: 2, color: 'blue' }} />

            {/* The iframe loading Domain B */}
            <iframe
              id='tokenReceiverIframe'
              title='Token Receiver'
              src={`${redirectToDomain}?parentOrigin=learning`}
              ref={iframeRef}
              style={{
                width: '300px', // Adjusted iframe width within the dialog
                height: '250px', // Reduced iframe height
                border: 'none',
                marginTop: '10px',
                display: 'none',
              }}
              // onLoad={() => {
              //   if (iframeRef.current && tokenToTransfer) {
              //     console.log(
              //       'Sending token via postMessage:',
              //       tokenToTransfer
              //     );
              //     iframeRef.current.contentWindow.postMessage(
              //       { token: tokenToTransfer, through: 'redirection' },
              //       redirectToDomain
              //     );
              //   }
              // }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
