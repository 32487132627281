export const DSATopicsDescription = [
  {
    action: 'Selection Sort',
    catagory: 'Sort',
    categoryId: 1,
    discription:
      'Selection Sort is a simple sorting algorithm that works by repeatedly finding theminimum element from the unsorted portion of the list and swapping it with the element at the beginning of the sorted portion. This process continues until the entire list is sorted.',
    gif: '/static/DSA-GIFs/SelectionSort.mp4',
    algorithm:
      " The core of the code implements the Selection Sort algorithm. It uses two nested loops for iteration. The outer loop selects elements one by one, and the inner loop iterates through subsequent elements. During this process, elements are visually highlighted. If a smaller element is identified, it's recorded as the minimum, and this visual representation includes patching and depatching elements as necessary. Should a smaller element be found in the inner loop (denoted as 'minJ' not equal to 'i'), a log message signals a swap. The code proceeds to swap these elements within the array 'D,' with each step being visually demonstrated.",
    subtopic_id: 11,
  },
  {
    action: 'Bubble Sort',
    catagory: 'Sort',
    categoryId: 1,
    discription:
      'Bubble Sort is a basic sorting algorithm that repeatedly steps through the list, compares adjacent elements, and swaps them if they are in the wrong order. This process is repeated until the entire list is sorted.',
    gif: '/static/DSA-GIFs/BubbleSort.mp4',
    algorithm:
      " The core of the code implements Bubble Sort. It begins by initializing variables, including 'N' (the array's length) and a 'swapped' flag. Inside a do-while loop, it iteratively compares adjacent elements in the array. During each pass, it selects elements for visualization and checks if the element at index 'i' is greater than the one at 'i - 1'. If so, it swaps them and sets the 'swapped' flag to true. These swaps and selections are visualized. The loop continues until no swaps are made in a pass, signifying that the array is sorted. After sorting, it logs the sorted array.",
    subtopic_id: 12,
  },
  {
    action: 'Insertion Sort',
    catagory: 'Sort',
    categoryId: 1,
    discription:
      'Insertion Sort is an elementary sorting algorithm that builds the final sorted array one item at a time. It takes each element from the list and inserts it into its correct position in the already sorted part of the array.',
    gif: '/static/DSA-GIFs/InsertionSort.mp4',
    algorithm:
      " It starts by generating a random array of 5 elements and displays it using a chart and an array tracer. The code then enters a loop to sort the array. In each iteration, it selects an element and compares it to the elements before it. If it's smaller, the code shifts the larger elements one position to the right until it finds the correct position for the selected element. The visualization updates with each comparison and swap, providing a step-by-step view of the sorting process.",
    subtopic_id: 13,
  },
  {
    action: 'Merge Sort',
    catagory: 'Sort',
    categoryId: 1,
    discription:
      'Merge Sort is a divide-and-conquer algorithm that divides the unsorted list into smaller sublists, sorts them recursively, and then merges them back together to obtain a sorted list. It is known for its efficiency and stable sorting.',
    gif: '/static/DSA-GIFs/MergeSort.mp4',
    algorithm:
      ' It starts by creating a 2D array with two rows: one for the original data and another for the auxiliary array used during the sorting process. The original data is a randomly generated array of 5 elements, and the visualization begins with this data. The mergeSort function performs the merge sort algorithm iteratively. It starts with a width of 1, merging adjacent elements, and then gradually increases the width until the entire array is sorted. The merge function combines two sorted subarrays, and the copy function copies the merged result back to the original array. The visualization updates with each merge and copy operation, providing a step-by-step view of the sorting process.',
    subtopic_id: 14,
  },
  {
    action: 'Quick Sort',
    catagory: 'Sort',
    categoryId: 1,
    discription:
      'Quick Sort is a fast, divide-and-conquer sorting algorithm that picks an element as a pivot and partitions the array around the pivot, such that all elements smaller than the pivot are moved to its left, and all elements greater than the pivot are moved to its right. The pivot is then recursively sorted, and the process is repeated on the left and right subarrays.',
    gif: '/static/DSA-GIFs/QuickSort.mp4',
    algorithm:
      'It initiates with a random array of 5 elements and displays it using a chart and an array tracer. The visualization begins by showing the original array. The core of this code is the quicksort function, which recursively partitions and sorts the array. It uses the partition function to select a pivot element, rearrange the array elements such that elements less than the pivot are on the left, and those greater are on the right. This process repeats for each partition until the entire array is sorted. Throughout the iterations, the visualization updates to illustrate the partitioning process, the pivot selection, and the rearrangements.',
    subtopic_id: 15,
  },
  {
    action: 'Heap Sort',
    catagory: 'Sort',
    categoryId: 1,
    discription:
      'Heap Sort is a comparison-based sorting algorithm that uses a binary heap data structure. It builds a max heap from the array and repeatedly extracts the maximum element (root of the heap) and places it at the end of the sorted array. The heap is then updated, and the process is repeated until the entire array is sorted.',
    gif: '/static/DSA-GIFs/HeapSort.mp4',
    algorithm:
      'The core of the code is the heapSort function, which performs Heap Sort iteratively. Initially, it builds a max-heap by calling the heapify function on all non-leaf nodes, ensuring that the largest element is at the root. It then repeatedly swaps the root (the largest element) with the last element of the heap, reduces the heap size, and calls heapify on the root to maintain the heap property. This process continues until the entire array is sorted. Throughout the iterations, the visualization updates to show the heap structure, the elements being swapped, and the final sorted array. The logger also records the original and sorted arrays for reference. Heap Sort is efficient and has a stable time complexity of O(n log n).',
    subtopic_id: 16,
  },
  {
    action: 'Count Sort',
    catagory: 'Sort',
    categoryId: 1,
    discription:
      'Count Sort is an efficient sorting algorithm that works by counting the number of occurrences of each element in the input array and using that information to reconstruct a sorted output array.',
    gif: '/static/DSA-GIFs/CountSort.mp4',
    algorithm:
      "It starts by creating three array tracers for the original array, counts, and the sorted array, displayed vertically. An initial array of 5 random integers between 0 and 9 is generated and displayed. The code then proceeds with the Counting Sort algorithm in three main phases. First, it counts the occurrences of each unique number in the original array, incrementing the corresponding count in the 'counts' array. This phase is visually represented by selecting the current element in the original array, updating the counts, and then deselecting the element. Next, it modifies the 'counts' array to represent the positions where each unique number should be placed in the sorted array. This is done by accumulating counts, and the visualization highlights this process by selecting, patching, and deselecting the counts. Finally, it creates the sorted array by placing each element in its correct position according to the counts. This phase involves selecting and deselecting elements in the original array, counts in the 'counts' array, and the sorted array, with each step visually represented through the tracers.",
    subtopic_id: 17,
  },
  {
    action: 'Linked List (Traversal)',
    catagory: 'Traversal',
    categoryId: 2,
    discription:
      'Linked List Traversal refers to the process of visiting and accessing each node in a linked list sequentially. It involves starting from the head of the linked list and moving through each node until the end.',
    gif: '/static/DSA-GIFs/LinkedListTraversal.mp4',
    algorithm:
      ' The traversal starts from the head node and iterates through the list, updating the visual representation by patching the corresponding element in the array and logging the value of each visited node. The traversal continues until the end of the list is reached. This visual representation effectively illustrates the concept of linked list traversal, showing how data is accessed sequentially from one node to the next, making it easier to comprehend the workings of linked data structures.',
    subtopic_id: 18,
  },
  {
    action: 'Hashing',
    catagory: 'Hash',
    categoryId: 18,
    discription:
      'Hashing is a technique that maps data to a fixed-size array called a hash table using a hash function. It enables efficient storage and retrieval of data based on key-value pairs.',
    gif: '/static/DSA-GIFs/Hashing.mp4',
    algorithm:
      "  It utilizes a 2D array to simulate the hash table and demonstrates how values are inserted into it one by one. A hash function determines the index in the table for each value, while collision handling is achieved by storing multiple values in a list at the same index when necessary. The code maintains two tracers, one to visualize the hash table's state and another to log each inserted value. This visual approach effectively illustrates the fundamental workings of a hash table, including hashing calculations, collision resolution, and value distribution across the table's indices.",
    subtopic_id: 19,
  },
  {
    action: 'Linear Search',
    catagory: 'Searching',
    categoryId: 3,
    discription:
      'Searching refers to the process of finding a specific element or value within a collection of data. It involves examining each element until a match is found or determining that the element is not present.',
    gif: '/static/DSA-GIFs/LinearSearch.mp4',
    algorithm:
      " The algorithm iterates through each element of the array sequentially, highlighting the current element for examination. It visually shows the process of checking each element against the target value. If a match is found, it logs the index where the target was found; otherwise, it continues until the end of the array is reached.Throughout the search process, the code uses the array tracer to select, patch, and depatch elements, providing a visual representation of the algorithm's progress. The log tracer records the steps taken during the search, providing a textual representation.This code effectively illustrates how linear search works by sequentially scanning each element in the array, making it clear how the algorithm finds or determines the absence of a target value.",
    subtopic_id: 20,
  },
  {
    action: 'Binary search',
    catagory: 'Searching',
    categoryId: 3,
    discription:
      'Binary Search is a fast search algorithm used to find a specific element in a sorted array or list.It repeatedly divides the search space in half by comparing the target value with the middle element until the desired element is found or determined to be absent.',
    gif: '/static/DSA-GIFs/BinarySearch.mp4',
    algorithm:
      ' The binary search algorithm starts by selecting the middle element of the array and comparing it to the target value. The code visually represents this process by highlighting the current range being searched, indicating the middle element, and logging the search progress. If the middle element matches the target, the algorithm reports the index where the target was found; otherwise, it narrows down the search range by adjusting the minIndex or maxIndex and repeats the process.',
    subtopic_id: 21,
  },
  {
    action: 'Union Find',
    catagory: 'Set',
    categoryId: 4,
    discription:
      'Union Find, also known as Disjoint Set, is a data structure that keeps track of a set of disjoint elements partitioned into several disjoint sets. It provides operations to create sets, merge sets, and find the set to which an element belongs.',
    gif: '/static/DSA-GIFs/UnionFind.mp4',
    algorithm:
      "The code demonstrates two essential operations: find and union. The find operation recursively traces the root of a given node while applying path compression for efficient future queries. The union operation combines two disjoint sets into one, considering the size of each set to optimize the structure's balance. The testUnionFind function showcases the union of various elements, visually depicting how elements in disjoint sets become part of the same set. It logs the root of each node after union operations and concludes by displaying the final structure's state.",
    subtopic_id: 22,
  },
  {
    action: 'Binary Search Tree',
    catagory: 'Tree',
    categoryId: 5,
    discription:
      'A Binary Search Tree (BST) is a binary tree data structure where each node has a key greater than all keys in its left subtree and smaller than all keys in its right subtree. It enables efficient searching, insertion, and deletion operations.',
    gif: '/static/DSA-GIFs/BinaryTreeSearch.mp4',
    algorithm:
      "The main components of this demonstration are the treeTracer for visualizing the tree structure, the arrayTracer for displaying the in-order traversal result, and the logger for logging the progress. The tree is initially displayed, and its layout is set to emphasize the root node. The arrayTracer is set to an array of dashes with the same length as the tree's size. The inOrder function recursively performs an in-order traversal of the binary tree. It traverses the left subtree, records the current node, and then traverses the right subtree. As the traversal progresses, the arrayTracer visually patches the elements in the order they are visited, and the logger logs the traversal progress. The code concludes by logging 'Finished' to indicate the completion of the in-order traversal.",
    subtopic_id: 23,
  },
  {
    action: 'Binary Tree',
    catagory: 'Tree',
    categoryId: 5,
    discription:
      'A Binary Tree is a hierarchical data structure consisting of nodes, each of which has at most two children. It does not necessarily have the properties of a BST.',
    gif: '/static/DSA-GIFs/BinaryTreeTraversal.mp4',
    algorithm:
      "The binary tree's structure is initially displayed with the root node emphasized. The code uses an array initialized with dashes to represent the in-order traversal result. The traversal function executes the in-order traversal recursively, following a left-root-right pattern. It begins at the specified root node and traverses the left subtree, then marks the current node, and finally, traverses the right subtree. As the traversal progresses, the code updates the traversal result array with the values of visited nodes.",
    subtopic_id: 24,
  },
  {
    action: 'Directed Graph',
    catagory: 'Graph',
    categoryId: 9,
    discription:
      'A Directed Graph, or Digraph, is a graph that is made up of a set of vertices and directed edges, where each edge has a specific direction from one vertex to another.',
    gif: '/static/DSA-GIFs/DirectedGraph.mp4',
    algorithm:
      " Function iterates through the elements array, creating adjacency lists to represent the graph's edges. It ensures that edges are added in both directions, creating an undirected graph. The following loop processes the elements array again, adding nodes and edges to the graphTracer. Each element is transformed into a node, and the connections are represented as edges.",
    subtopic_id: 25,
  },
  {
    action: 'Undirected Graph',
    catagory: 'Graph',
    categoryId: 9,
    discription:
      'An Undirected Graph is a graph that is made up of a set of vertices and undirected edges, where the edges have no specified direction and connect two vertices symmetrically.',
    gif: '/static/DSA-GIFs/UndirectedGraph.mp4',
    algorithm:
      " Function iterates through the elements array, creating adjacency lists to represent the graph's edges. It ensures that edges are added in both directions, creating an undirected graph. The following loop processes the elements array again, adding nodes and edges to the graphTracer. Each element is transformed into a node, and the connections are represented as edges.",
    subtopic_id: 26,
  },
  {
    action: 'Segment Tree Operations',
    catagory: 'Tree',
    categoryId: 5,
    discription:
      'A Segment Tree is a tree-like data structure used for efficient querying and updating of intervals or segments within an array. It allows various range operations, such as finding the sum, minimum, maximum, or updating values within a specified range.',
    gif: '/static/DSA-GIFs/SegmentTreeOperations.mp4',
    algorithm:
      ' The central function, simulates the traversal process within the segment tree. It iterates through nodes and segments, tracing each step in the tree and pausing for visualization purposes. The traversal initially explores left branches and segments until reaching leaf nodes, subsequently printing node values and moving to right branches. The traversal is carried out in a hierarchical manner, creating a clear visual representation of how in-order traversal traverses the segment tree. The root index is used as a starting point, and the traversal process covers the entire segment, while the logger records each step.',
    subtopic_id: 27,
  },
  {
    action: 'Recursion (Nth Factorial)',
    catagory: 'Dynamic Programming',
    categoryId: 19,
    discription:
      'Recursion is a programming technique where a function calls itself to solve a problem by breaking it down into smaller subproblems. Each recursive call works on a smaller input until a base case is reached.',
    gif: '/static/DSA-GIFs/Recursion.mp4',
    algorithm:
      "The factorial computation occurs within the function, which employs recursion. For each recursive call, the function calculates the factorial result and updates the corresponding element. This process repeats recursively, iteratively calculating factorials for decreasing values of 'num' until it reaches zero, at which point it returns 1.",
    subtopic_id: 28,
  },
  {
    action: 'PreOrder Traversal',
    catagory: 'Tree',
    categoryId: 5,
    discription:
      'PreOrder Traversal is a tree traversal algorithm that visits the root node first, followed by the left subtree, and then the right subtree.',
    gif: '/static/DSA-GIFs/PreOrderTraversal.mp4',
    algorithm:
      ' The pre-order traversal starts at a specified root node (in this case, node 5) and follows a specific order: first, it visits the current node, then prints its value, and finally explores its left and right children. The process is implemented using recursive function calls.',
    subtopic_id: 29,
  },
  {
    action: 'InOrder Traversal',
    catagory: 'Tree',
    categoryId: 5,
    discription:
      'InOrder Traversal is a tree traversal algorithm that visits the left subtree first, followed by the root node, and then the right subtree.',
    gif: '/static/DSA-GIFs/InOrderTraversal.mp4',
    algorithm:
      ' The in-order traversal initiates from a specified root node (in this instance, node 5) and adheres to a specific sequence: first, it explores the left child, then it visits the current node, followed by printing its value, and finally, it explores the right child. This process is executed using recursive function calls.',
    subtopic_id: 30,
  },
  {
    action: 'PostOrder Traversal',
    catagory: 'Tree',
    categoryId: 5,
    discription:
      'PostOrder Traversal is a tree traversal algorithm that visits the left subtree first, followed by the right subtree, and then the root node.',
    gif: '/static/DSA-GIFs/PostOrderTraversal.mp4',
    algorithm:
      ' The post-order traversal initiates from a specified root node (in this case, node 5) and adheres to a specific sequence: it explores the left child, then the right child, and finally visits the current node before printing its value. This process is executed using recursive function calls.',
    subtopic_id: 31,
  },
  {
    action: 'Minimum spanning Tree (Prim)',
    catagory: 'Tree',
    categoryId: 5,
    discription:
      "Prim's algorithm is a greedy algorithm that finds a minimum spanning tree for a connected weighted undirected graph. The algorithm starts with an arbitrary vertex and gradually grows the spanning tree by adding the minimum weight edge that connects a vertex in the tree to a vertex outside the tree. It continues this process until all vertices are included in the tree, resulting in a minimum spanning tree.",
    gif: '/static/DSA-GIFs/MSTPrims.mp4',
    algorithm:
      "Prim's algorithm starts with an initial node (in this case, node 0) and iteratively grows the minimum spanning tree by selecting the edge with the smallest weight that connects a visited node to an unvisited node. Throughout the iteration, it maintains a set of visited nodes ('D') and calculates the total sum of selected edge weights ('sum'). During each iteration, the code searches for the next edge to add to the minimum spanning tree. It evaluates all edges connected to the visited nodes, ensuring that the selected edge connects a visited node ('D[i]') to an unvisited one ('!D[j]').",
    subtopic_id: 32,
  },
  {
    action: 'Minimum spanning Tree (Kruskal)',
    catagory: 'Tree',
    categoryId: 5,
    discription:
      "Kruskal's algorithm is another greedy algorithm for finding a minimum spanning tree in a connected weighted undirected graph. It starts by sorting all the edges of the graph in non-decreasing order of their weights. Then, it iterates over the sorted edges and adds each edge to the spanning tree if it does not create a cycle with the edges already included. The algorithm maintains a disjoint set data structure to efficiently check for cycles.",
    gif: '/static/DSA-GIFs/MSTKrushkals.mp4',
    algorithm:
      " Kruskal's algorithm starts by creating a list of all edges in the graph and sorting them in ascending order based on their weights. It then initializes a disjoint-set data structure ('t') where each vertex is initially in its own disjoint set.During each iteration, the code examines the edges in sorted order and checks if adding an edge to the minimum spanning tree would create a cycle. If adding the edge doesn't form a cycle, it includes the edge in the minimum spanning tree and merges the disjoint sets of the two vertices connected by the edge.",
    subtopic_id: 33,
  },
  {
    action: 'Shortest Path algorithms',
    catagory: 'Graph',
    categoryId: 9,
    discription:
      'Shortest Path algorithms aim to find the most optimal path between two vertices in a graph, taking into consideration the weights assigned to the edges.',
    gif: '/static/DSA-GIFs/DijkstraShortestPath.mp4',
    algorithm:
      " Dijkstra's algorithm iteratively selects the vertex with the minimum distance from the source vertex, marks it as visited, and relaxes the distances to its neighboring vertices. This process continues until all vertices are visited or the target vertex is reached. During each iteration, the code highlights the selected vertex and updates the shortest distance values. The log tracer records information about the algorithm's progress, including the source and target vertices and the shortest path length.",
    subtopic_id: 34,
  },
  {
    action: 'Bridge in the graph',
    catagory: 'Graph',
    categoryId: 9,
    discription:
      'In a graph, a bridge is an edge whose removal would increase the number of connected components in the graph. It plays a crucial role in determining the connectivity of a graph.',
    gif: '/static/DSA-GIFs/Bridgeinthegraph.mp4',
    algorithm:
      " DFS is a recursive algorithm that explores a graph's nodes systematically, visiting a node and then recursively visiting its unvisited neighbors. The algorithm maintains a 'low' array to track the lowest reachable ancestor for each node and a 'visited' array to mark visited nodes. The 'time' variable increments during traversal, helping to identify bridges or articulation points. In each iteration, the code represents the traversal by visiting and leaving nodes. If a node's 'low' value is greater than the current time, it's marked in red to indicate a bridge in the graph.",
    subtopic_id: 35,
  },
  {
    action: 'Articulation point of the graph',
    catagory: 'Graph',
    categoryId: 9,
    discription:
      'An articulation point, or cut vertex, is a vertex in a graph whose removal would increase the number of connected components in the graph. It represents critical points of connectivity.',
    gif: '/static/DSA-GIFs/Articulationpointofthegraph.mp4',
    algorithm:
      " DFS systematically explores the graph's nodes, tracking the lowest reachable ancestor ('low' array) for each node while marking them as visited ('visited' array). The algorithm also identifies articulation points, which are nodes whose removal would increase the graph's number of connected components. In each iteration, the code visits and leaves nodes. to represent traversal. If a node qualifies as an articulation point, it's marked in red.",
    subtopic_id: 36,
  },
  {
    action: 'Max Heapify',
    catagory: 'Heap',
    categoryId: 8,
    discription:
      'Max Heapify is an operation used to maintain the max heap property of a binary heap, where the parent nodes have values greater than or equal to their children.',
    gif: '/static/DSA-GIFs/MaxHeapify.mp4',
    algorithm:
      ' Heap Sort first builds a max heap, a binary tree where a parent node has a greater value than its child nodes. It begins by visualizing the original array and then transforms it into a max heap. During this process, it identifies the largest element in the heap and swaps it with the root node, continually decreasing the heap size and ensuring the largest elements are pushed to the end of the array. In each step, the code highlights the elements being swapped, updates the chart to reflect the current array state, and logs the swap operation. This visualization helps users follow the sorting process step by step. Once the max heap is built, Heap Sort repeatedly extracts the maximum element from the root (the first element of the array), swaps it with the last unsorted element, and then restores the max heap property. The process continues until the entire array is sorted.',
    subtopic_id: 37,
  },
  {
    action: 'Min Heapify',
    catagory: 'Heap',
    categoryId: 8,
    discription:
      'Min Heapify is an operation used to maintain the min heap property of a binary heap, where the parent nodes have values less than or equal to their children.',
    gif: '/static/DSA-GIFs/MinHeapify.mp4',
    algorithm:
      'Heap Sort begins by displaying the original array and then transforms it into a min heap, a binary tree where a parent node has a smaller value than its child nodes. The process involves comparing elements and swapping them to satisfy the min heap property. The code highlights the elements being swapped, updates the chart to reflect the current array state, and logs each swap operation. This visualization enables users to follow the sorting process step by step. Once the min heap is constructed, Heap Sort repeatedly extracts the minimum element from the root (the first element of the array), swaps it with the last unsorted element, and restores the min heap property. This process continues until the entire array is sorted.',
    subtopic_id: 38,
  },
  {
    action: 'Sliding Window',
    catagory: 'Dynamic Programming',
    categoryId: 19,
    discription:
      "The Sliding Window algorithm is a technique used to solve problems that involve arrays or sequences by efficiently processing a subarray or subsequence of a fixed size within the main array. The algorithm slides through the array, updating the window's contents, and efficiently calculates the desired result without redundant computations.",
    gif: '/static/DSA-GIFs/SlidingWindow.mp4',
    algorithm:
      "The algorithm starts by initializing the sum of the first three elements and selecting these elements in the tracer to visually highlight them. The logger logs the initial sum. The code then enters a loop that iterates through the array, starting from the fourth element. In each iteration, it updates the sum by subtracting the first element of the previous window and adding the current element. This simulates the 'sliding' of the window over the array. It also selects and deselects elements in the tracer to show the current window. Throughout the process, the code tracks the maximum sum encountered and updates it when a new maximum is found.",
    subtopic_id: 39,
  },
  {
    action: 'Fibonacci Series',
    catagory: 'Dynamic Programming',
    categoryId: 19,
    discription:
      'Dynamic Programming is a technique for solving complex problems by breaking them down into simpler overlapping subproblems and storing the results of these subproblems to avoid redundant computations.',
    gif: '/static/DSA-GIFs/FibonacchiSeries.mp4',
    algorithm:
      " The process begins by initializing the first two elements of the sequence as 1 and 1. Then, it enters a loop that iterates from the third element up to the specified 'index.' During each iteration, the code calculates the next Fibonacci number by adding the two preceding numbers.",
    subtopic_id: 40,
  },
  {
    action: 'Sieve of eratosthenes',
    catagory: 'Dynamic Programming',
    categoryId: 19,
    discription:
      'Dynamic Programming is a technique for solving complex problems by breaking them down into simpler overlapping subproblems and storing the results of these subproblems to avoid redundant computations.',
    gif: '/static/DSA-GIFs/Sieveoferatosthenes.mp4',
    algorithm:
      " The algorithm starts by initializing the tracer with the initial array 'a' and the log tracer for recording progress. It then marks 1 as not prime and highlights it in the array tracer. The core of the algorithm begins with the loop from 2 to 'N,' where it checks if a number 'i' is marked as composite in the 'b' array. If 'i' is not marked, it is identified as prime. This is visually logged, and 'i' is highlighted as a prime number in the array tracer. Next, the algorithm iterates through multiples of 'i' from '2i' up to 'N,' marking them as composite in the 'b' array.",
    subtopic_id: 41,
  },
  {
    action: 'Knapsack',
    catagory: 'Dynamic Programming',
    categoryId: 19,
    discription:
      'The Knapsack algorithm, specifically the 0/1 Knapsack problem, is a combinatorial optimization problem. Given a set of items, each with a specific weight and value, the task is to determine the most valuable combination of items to include in a knapsack with a limited weight capacity. The goal is to maximize the total value of the items in the knapsack without exceeding its weight capacity.',
    gif: '/static/DSA-GIFs/Knapsack.mp4',
    algorithm:
      " The algorithm initializes a 2D array 'DP' to store intermediate results, where 'DP[i][j]' represents the maximum value achievable with the first 'i' items and a knapsack capacity of 'j.' The code sets up tracers to visualize this array along with arrays 'valuesTracer' and 'weightsTracer' to show the item values and weights. The algorithm then iterates through each item and capacity combination. For each combination, it checks if adding the current item to the knapsack (if it fits) would yield a greater value ('A') than not adding it ('B'). If 'A' is greater, it updates 'DP[i][j]' and visualizes the process by patching the value into the 'DP' array.",
    subtopic_id: 42,
  },
  {
    action: 'N-Queens',
    catagory: 'Backtracking',
    categoryId: 14,
    discription:
      ' The N-Queens algorithm is a classical problem in combinatorial optimization and chessboard configuration. Given an N×N chessboard, the task is to place N queens on the board in such a way that no two queens attack each other (i.e., no two queens share the same row, column, or diagonal). The algorithm aims to find all distinct solutions for the N-Queens problem.',
    gif: '/static/DSA-GIFs/NQueens.mp4',
    algorithm:
      "The visualization consists of a chessboard represented by a 2D array ('board') and the positions of queens represented by another 2D array ('queens'). It uses tracers to depict the state of the chessboard, the queen positions, and a logger to track progress. The algorithm ('nQ') is a recursive backtracking approach to find a valid arrangement of queens. It starts by attempting to place the first queen in the first column, then recursively places the subsequent queens in successive columns while ensuring that no two queens threaten each other. The visualization allows users to observe the process of trying different queen placements and backtracking when conflicts are detected. The code validates each queen placement to ensure it doesn't conflict with existing queens, considering rows, columns, and diagonals. If a valid placement is found for all queens, it returns 'true,' indicating success.",
    subtopic_id: 43,
  },
];
