import React from 'react';
import { InlineWidget } from 'react-calendly';

/**
 * @typedef {Object} CalendlyProps
 * @property {string} url - The Calendly URL.
 * @property {string | null} pathname - The pathname to determine form type.
 */

/**
 * Calendly component to embed a Calendly widget.
 *
 * @param {CalendlyProps} props - The props for the Calendly component.
 */
const Calendly = ({ url, pathname = null }) => {
  //! Determine if the Calendly form is for signup (for styling)
  const isSignupForm = pathname?.startsWith('/sign-up');

  const widgetStyles = isSignupForm
    ? { width: '100vw', height: '40rem' }
    : undefined;

  return (
    <div style={{ marginTop: '2rem' }}>
      <InlineWidget
        url={url}
        styles={widgetStyles}
      />
    </div>
  );
};

export default Calendly;
